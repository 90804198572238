import { useFormConnect } from '../../../../hooks/useFormConnect';
import { Select } from '../../Select/Select';

// Salgspant i motorvogn	NYSP
// Avtale om factoring	NYFA
// Avtale om leasing	NYLA
// Pant i driftstilbehør	NYDT
// Pant i fiskeredskaper	NYFP
// Pant i motorvogner og anleggsmaskiner	NYKA
// Pant i redskaper, besetning, avling mv. i landbruksnæring	NYLP
// Pant i varelager	NYVL
export const VEHICLE_AND_MACHINERY = 'NYKA';
export const STORAGE = 'NYVL';
export const AGRICULTURE = 'NYLP';
export const SALES_PLEDGE = 'NYSP';
export const FACTORING = 'NYFA';
export const FISHING_TOOLS = 'NYFP';
export const OPERATING_EQUIPMENT = 'NYDT';
export const LEASING = 'NYLA';

export function PledgeType({ name, t, user }) {
  const leasing = {
    value: 'leasing',
    label: t('leasing', 'Leasing'),
  };
  let options = [leasing];
  if (!user?.role.includes('leasing')) {
    options = [
      {
        value: 'operatingEquipment',
        label: t('operating-equipment', 'Driftstilbehør'),
      },
      {
        value: 'factoring',
        label: t('factoring', 'Factoring'),
      },
      {
        value: 'storage',
        label: t('storage', 'Varelager'),
      },
      {
        value: 'individualMotor',
        label: t('individual-motor', 'Individuelle motorvogner'),
      },
      {
        value: 'motor',
        label: t('collected-motor', 'Samlede motorvogner'),
      },
      {
        value: 'salesPledge',
        label: t('sales-pledge', 'Salgspant'),
      },
      {
        value: 'fishingTools',
        label: t('fishing-tools', 'Fiskeredskaper'),
      },
      {
        value: 'agriculture',
        label: t('agriculture', 'Landbrukspant'),
      },
      leasing,
    ];
  }

  return (
    <Select id={name} options={options} label={t('pledge-type', 'Pantetype')} />
  );
}
