import { ReactNode } from 'react';
import clsx from 'clsx';
type Props = {
  children: ReactNode;
  split?: boolean;
};

export function Layout({ children, split = false }: Props) {
  return (
    <div
      className={clsx(
        split
          ? 'grid-cols-2 grid place-content-start place-items-start [&>children]:w-full'
          : 'flex flex-col items-center ',
        'gap-8 w-full p-8 box-border',
      )}
    >
      {children}
    </div>
  );
}
