import { HTMLInputTypeAttribute, forwardRef, HTMLAttributes } from 'react';
import { ConnectedProps } from '../../../../types/ConnectedProps';
import { InputBoxInput } from './InputBox.Input';
import { InputBoxMaskedInput } from './InputBox.MaskedInput';
import clsx from 'clsx';
import { Mask } from './InputBox.masks';
import { useFlowContext } from '../../../../hooks/useFlowContext';

export type InputBoxProps<T> = {
  name: string;
  placeholder?: string;
  type?: HTMLInputTypeAttribute;
  mask?: Mask;
  autoComplete?: string;
  disabled?: boolean;
  inputClassNames?: string;
  inputMode?: HTMLAttributes<HTMLLIElement>['inputMode'];
  maxLength?: number;
  hiddenBorder?: boolean;
  prefill?: string;
  min?: number;
  max?: number;
  pattern?: string;
} & ConnectedProps<T>;

export type InputProps = {
  name: string;
  placeholder?: string;
  type?: HTMLInputTypeAttribute;
  inputMode?: HTMLAttributes<HTMLLIElement>['inputMode'];
  autoComplete?: string;
  mask?: Mask;
  disabled?: boolean;
  onChange: (e: any) => void;
  value?: string | number;
  invalid: boolean;
  errorId: string;
  className: string;
  min?: number;
  max?: number;
  maxLength?: number;
  pattern?: string;
};

// eslint-disable-next-line react/display-name
export const InputBox = forwardRef<HTMLInputElement, InputBoxProps<string>>(
  (
    {
      onChange,
      value,
      name,
      type = 'text',
      inputMode,
      label,
      placeholder,
      autoComplete,
      error,
      mask,
      min,
      pattern,
      max,
      disabled,
      inputClassNames,
      maxLength,
      hiddenBorder = false,
      prefill,
    },
    ref,
  ) => {
    const errorId = `${name}-error`;
    const invalid = false;

    const data = useFlowContext();

    const hasPropsInProps = data?.props ?? data;

    const { t } = hasPropsInProps;
    const styles = clsx(
      'focus:border-transparent rounded-md focus:ring-2 block h-input w-full border p-2 border-gray-300 outline-none ring-0',
      inputClassNames,
      disabled
        ? 'text-disabled'
        : 'transition-[text-indent_background-color] hover:bg-ecru hover:indent-2 focus:border-0 focus:bg-ecru focus:indent-2 focus:outline-none focus:ring-0',
      error ? 'focus:outline-none !bg-error' : '',
    );

    return (
      <div>
        {label && (
          <label htmlFor={name} className="block">
            {label}
          </label>
        )}
        <div className={clsx('relative', label ? 'mt-1' : '')}>
          {prefill && (
            <p className="absolute bottom-0 z-10 mb-[2px] pb-4 md:mb-0">
              {prefill}
            </p>
          )}
          <div
            className={`${clsx(
              'border-b-line pb-[4px]',
              disabled ? 'border-b-disabled' : 'border-b-black',
              hiddenBorder && 'border-b-0',
            )}`}
          >
            {mask ? (
              <InputBoxMaskedInput
                type={type}
                inputMode={inputMode}
                ref={ref}
                pattern={pattern}
                name={name}
                errorId={errorId}
                placeholder={placeholder}
                autoComplete={autoComplete}
                invalid={invalid}
                value={value}
                onChange={(val) => onChange(val)}
                mask={mask}
                min={min}
                max={max}
                disabled={disabled}
                className={`${clsx(styles, prefill && 'relative pl-12')}`}
                maxLength={maxLength}
              />
            ) : (
              <InputBoxInput
                type={type}
                inputMode={inputMode}
                ref={ref}
                name={name}
                errorId={errorId}
                placeholder={placeholder}
                autoComplete={autoComplete}
                invalid={invalid}
                pattern={pattern}
                min={min}
                max={max}
                value={value}
                disabled={disabled}
                onChange={(e) => onChange(e.target.value)}
                className={`${clsx(styles, prefill && 'relative pl-12')}`}
                maxLength={maxLength}
              />
            )}
          </div>
        </div>
        {error && <p className="text-red-500 pt-1">{t(error)}</p>}
      </div>
    );
  },
);
