import { FlowContext } from '../../../hooks/useFlowContext';
import { TaskProps } from '../../../types/TaskProps';
import { ConfirmRejectedDocument } from '../../../components/_shared/forms/schemas/ConfirmRejectedDocument/ConfirmRejectedDocument';

export function RejectedDocument(props: TaskProps) {
  return (
    <FlowContext.Provider value={props}>
      <ConfirmRejectedDocument props={props} />
    </FlowContext.Provider>
  );
}
