import { ChangeEvent, forwardRef } from 'react';
import { ConnectedProps } from '../../../../types/ConnectedProps';
import { useFlowContext } from '../../../../hooks/useFlowContext';
export type CheckBoxProps<T> = {
  name: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  index?: number;
  id?: string;
  values?: Array<string>;
} & ConnectedProps<T> &
  Omit<ConnectedProps<string>, 'value'> & {
    index?: T extends Array<string> ? number : never;
    values?: T extends Array<string> ? Array<string> : never;
  };

// eslint-disable-next-line react/display-name
export const CheckBox = forwardRef<
  HTMLInputElement,
  CheckBoxProps<boolean | Array<string>>
>(({ name, label, value, error, onChange, id, index, values }, ref) => {
  const errorId = `${name}-error`;
  const props = useFlowContext();
  const hasNestedProps = props?.props ?? props;
  const { t } = hasNestedProps;

  return (
    <div>
      <div className="relative flex items-center gap-2">
        <input
          value={
            typeof value === 'boolean'
              ? value.toString()
              : values?.[index] ?? ''
          }
          id={id || name}
          name={name}
          ref={ref}
          type="checkbox"
          checked={
            Array.isArray(value)
              ? value.some((existingValue) => existingValue === values[index])
              : value
          }
          onChange={(event) => {
            if (Array.isArray(value) && event.target.checked) {
              onChange([...value, event.target.value]);
            } else if (Array.isArray(value)) {
              onChange(value.filter((value) => value !== event.target.value));
            } else {
              onChange(event);
            }
          }}
          className="
                border-pink text-pink checked:text-pink 
                hover:text-pink hover:checked:text-pink 
                focus:text-pink  focus:checked:text-pink  focus-visible:outline-pink
                h-4
                w-4
                cursor-pointer
                transition-colors focus:outline-none
                focus:ring-0"
        />
        <label
          className="w-full text-burgundy cursor-pointer font-light first-letter:capitalize"
          htmlFor={name}
        >
          {t(label)}
        </label>
      </div>
      {error && <div className="mt-2 text-red-500">{t(error)}</div>}
    </div>
  );
});
