import { useQuery } from '@tanstack/react-query';
import { Button } from '../../../Button/Button';
import { getAttachments } from '../../../../../api/api';
import { LoadingSpinner } from '../../../Spinner/Spinner';

export function ConfirmRejectedDocument({ props }) {
  const access_token = props.user.access_token;
  const currentPledge = props.flow?.data?.pledges?.find((pledge) => {
    return pledge.pledgeId === props.task?.variables?.pledge?.pledgeId;
  });
  const flowId = props.flow.flowId;
  const attachmentKey = currentPledge?.document?.filledDocument?.attachmentKey;
  const reasonForDenial = currentPledge?.registration?.status?.avslagsgrunn;
  const tinglysingStatus = currentPledge?.registration?.status?.status;

  const { data, isFetching, isError, error, refetch } = useQuery({
    queryKey: [attachmentKey],
    queryFn: () => getAttachments(access_token, flowId, attachmentKey),
  });

  const { close, complete, t } = props;

  if (isFetching) {
    return (
      <div className="flex items-center justify-center h-full flex-col">
        <LoadingSpinner />
        <p>{t('loading-document', 'Laster inn dokument')}...</p>
      </div>
    );
  }

  if (isError) {
    return (
      <div className="flex items-center justify-start mt-8 gap-8 flex-col">
        <p>
          {t(
            'error-occured',
            'Det oppstod en feil, vennligst lukk vinduet eller prøv igjen.',
          )}
        </p>{' '}
        <div className="flex gap-4">
          <Button onClick={() => refetch()}>
            {t('try-again', 'Prøv igjen')}
          </Button>
          <Button primary={false} type="button" onClick={() => close()}>
            {t('close', 'Lukk')}
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="grid grid-cols-3 gap-4">
      <iframe
        className=" min-h-screen w-full col-span-2 border-r"
        title="pdf"
        src={data}
      />
      <div className=" px-2 py-4 flex flex-col gap-6">
        <p className="uppercase">
          {t('status', 'Status')}: {t(`${tinglysingStatus}`)}
        </p>
        <p>{t('explanation', 'Forklaring')}:</p>
        <p>{reasonForDenial}</p>
        <div className="flex gap-4">
          <Button type="button" onClick={() => complete()}>
            {t('proceed', 'Gå videre')}
          </Button>
          <Button primary={false} onClick={() => close()}>
            {t('close', 'Lukk')}
          </Button>
        </div>
      </div>
    </div>
  );
}
