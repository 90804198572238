/**
 * This is the shared error context component, the component displays warnings.
 * @param {Object} props
 * @param {string} props.id
 * @param {Object} props.error
 * @param {string} props.error.message
 */
import { useState } from 'react';
import { X } from 'lucide-react';

type WarningMessageProps = {
  message: string;
  appendixMessage?: string;
};

export function WarningMessage({
  message,
  appendixMessage,
}: WarningMessageProps) {
  const [showAppendix, setShowAppendix] = useState(false);
  const [closed, setClosed] = useState(false);

  if (!message || message === '') {
    return null;
  }

  return !closed ? (
    <div className="w-full p-4 bg-yellow-300 flex flex-col text-gray-800 justify-center gap-2">
      <div className="flex justify-between">
        <p className=" text-sm">{message}</p>
        {appendixMessage && (
          <button
            type="button"
            onClick={() => setShowAppendix(!showAppendix)}
            className=" w-max text-sm underline hover:text-gray-500"
          >
            {showAppendix ? 'Lukk' : 'Hva betyr dette?'}
          </button>
        )}
        <button
          type="button"
          onClick={() => setClosed(!closed)}
          className=" w-max text-sm underline hover:text-gray-500"
        >
          {<X size={20} />}
        </button>
      </div>
      {showAppendix && (
        <p className=" flex border-t border-gray-800 pt-2">{appendixMessage}</p>
      )}
    </div>
  ) : (
    ''
  );
}
