export const t = {
  'no-failed-cases-message': {
    no: 'Ingen feilede saker',
    en: 'No failed cases',
    dk: 'Ingen fejlede sager',
    dev: 'no-failed-cases-message',
  },
  'notificaions-title': {
    no: 'Varsler',
    en: 'Notifications',
    dk: 'Notifikationer',
    dev: 'notificaions-title',
  },
  'hours-ago': {
    no: 'timer siden',
    en: 'hours ago',
    dk: 'timer siden',
    dev: 'hours-ago',
  },
  'minutes-ago': {
    no: 'minutter siden',
    en: 'minutes ago',
    dk: 'minutter siden',
    dev: 'minutes-ago',
  },
  'no-assignee': {
    no: 'Ingen saksbehandler',
    en: 'No assignee',
    dk: 'Ingen sagsbehandler',
    dev: 'no-assignee',
  },
  'error-unknown': {
    no: 'Ukjent feil',
    en: 'Unknown error',
    dk: 'Ukendt fejl',
    dev: 'error-unknown',
  },
  'days-ago': {
    no: 'dager siden',
    en: 'days ago',
    dk: 'dage siden',
    dev: 'days-ago',
  },
  DOCUMENT_CONTROL: {
    no: 'Dokumentkontroll',
    en: 'Document control',
    dk: 'Dokumentkontrol',
    dev: 'DOCUMENT_CONTROL',
  },
  FAILED_CASES: {
    no: 'Mislykkede saker',
    en: 'Failed cases',
    dk: 'Fejlede sager',
    dev: 'FAILED_CASES',
  },
  TERMINATED_CASES: {
    no: 'Avsluttede saker',
    en: 'Terminated cases',
    dk: 'Afsluttede sager',
    dev: 'TERMINATED_CASES',
  },
  EXPIRED_CASES: {
    no: 'Utgåtte saker',
    en: 'Expired cases',
    dk: 'Udløbne sager',
    dev: 'EXPIRED_CASES',
  },
  COMPLETED_CASES: {
    no: 'Fullførte saker',
    en: 'Completed cases',
    dk: 'Færdige sager',
    dev: 'COMPLETED_CASES',
  },
  TINGLYSING: {
    no: 'Tinglysing',
    en: 'Tinglysing',
    dk: 'Tinglysning',
    dev: 'TINGLYSING',
  },
  AWAITING_CONFIGURE_SIGNEES: {
    no: 'Venter på konfigurasjon av underskrivere',
    en: 'Awaiting configure signees',
    dk: 'Afventer konfiguration af underskrivere',
    dev: 'AWAITING_CONFIGURE_SIGNEES',
  },
  AWAITING_ADD_PLEDGE_INFORMATION: {
    no: 'Venter på å legge til panteinformasjon',
    en: 'Awaiting add pledge information',
    dk: 'Afventer tilføjelse af pantoplysninger',
    dev: 'AWAITING_ADD_PLEDGE_INFORMATION',
  },
  AWAITING_DOCUMENT_CONTROL: {
    no: 'Venter på dokumentkontroll',
    en: 'Awaiting document control',
    dk: 'Afventer dokumentkontrol',
    dev: 'AWAITING_DOCUMENT_CONTROL',
  },
  AWAITING_DOCUMENT_CONTROL_REGISTRATION: {
    no: 'Venter på registrering av dokumentkontroll',
    en: 'Awaiting document control registration',
    dk: 'Afventer registrering af dokumentkontrol',
    dev: 'AWAITING_DOCUMENT_CONTROL_REGISTRATION',
  },
  AWAITING_SIGNING_DOCUMENTS: {
    no: 'Venter på signering av dokumenter',
    en: 'Awaiting signing documents',
    dk: 'Afventer underskrift af dokumenter',
    dev: 'AWAITING_SIGNING_DOCUMENTS',
  },
  AWAITING_REGISTRATION: {
    no: 'Venter på registrering',
    en: 'Awaiting registration',
    dk: 'Afventer registrering',
    dev: 'AWAITING_REGISTRATION',
  },
  AWAITING_DOWNLOAD_FILES_FOR_ARCHIVING: {
    no: 'Venter på nedlasting av filer for arkivering',
    en: 'Awaiting download files for archiving',
    dk: 'Afventer download af filer til arkivering',
    dev: 'AWAITING_DOWNLOAD_FILES_FOR_ARCHIVING',
  },
  CASES: {
    no: 'Saker',
    en: 'Cases',
    dk: 'Sager',
    dev: 'CASES',
  },
  MY_CASES: {
    no: 'Mine saker',
    en: 'My cases',
    dk: 'Mine sager',
    dev: 'MY_CASES',
  },
  ADD_PLEDGE_INFORMATION: {
    no: 'Legg til panteinformasjon',
    en: 'Add pledge information',
    dk: 'Tilføj pantoplysninger',
    dev: 'ADD_PLEDGE_INFORMATION',
  },
  SIGNING_DOCUMENTS: {
    no: 'Signere dokumenter',
    en: 'Signing documents',
    dk: 'Underskrive dokumenter',
    dev: 'SIGNING_DOCUMENTS',
  },
  CONFIGURE_SIGNEES: {
    no: 'Konfigurere underskrivere',
    en: 'Configure signees',
    dk: 'Konfigurer underskrivere',
    dev: 'CONFIGURE_SIGNEES',
  },
  DOCUMENT_CONTROL_REGISTRATION: {
    no: 'Dokumentkontroll registrering',
    en: 'Document control registration',
    dk: 'Dokumentkontrolregistrering',
    dev: 'DOCUMENT_CONTROL_REGISTRATION',
  },
  REGISTRATION: {
    no: 'Registrering',
    en: 'Registration',
    dk: 'Registrering',
    dev: 'REGISTRATION',
  },
  DOWNLOAD_FILES_FOR_ARCHIVING: {
    no: 'Last ned filer for arkivering',
    en: 'Download files for archiving',
    dk: 'Download filer til arkivering',
    dev: 'DOWNLOAD_FILES_FOR_ARCHIVING',
  },
};
