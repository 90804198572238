import { MainLogo } from './MainLogo';
import { LanguageSelect } from './LanguageSelect';
import { NotificationsButton } from './Notifications/NotificationsButton';

export function Header({ user }) {
  return (
    <div className="flex flex-row items-center justify-between text-xs widthFull text-gray-800">
      <div className="flex gap-4 items-center justify-center">
        <MainLogo />
      </div>
      <div className="flex gap-4 items-center">
        <NotificationsButton user={user} />
        <LanguageSelect />
      </div>
    </div>
  );
}
