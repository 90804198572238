import { useFormConnect } from '../../../../hooks/useFormConnect';
import { InputBox } from '../../Inputs/InputBox';

export const TextField = ({
  name,
  label,
  maxLength = 9999,
}: {
  name: string;
  label: string;
  maxLength?: number;
}) => {
  const props = useFormConnect(name);
  return <InputBox maxLength={maxLength} label={label} {...props}></InputBox>;
};
