import React, { forwardRef } from 'react';
import { InputProps } from './InputBox';

export const InputBoxInput = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      onChange,
      value,
      name,
      type,
      placeholder,
      inputMode,
      invalid,
      errorId,
      autoComplete,
      className,
      min,
      max,
      pattern,
      disabled,
      maxLength,
    },
    ref,
  ) => {
    return (
      <input
        type={type}
        inputMode={inputMode}
        ref={ref}
        name={name}
        id={name}
        min={min}
        pattern={pattern}
        max={max}
        className={className}
        placeholder={placeholder}
        aria-invalid={invalid}
        aria-errormessage={errorId}
        value={value}
        onChange={(e) => onChange(e)}
        autoComplete={autoComplete}
        disabled={disabled}
        maxLength={maxLength}
      />
    );
  },
);

InputBoxInput.displayName = 'InputBoxInput';
