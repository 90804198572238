import { FlowContext } from '../../../hooks/useFlowContext';
import { TaskProps } from '../../../types/TaskProps';
import {
  ApproveDocumentSchema,
} from '../../../components/_shared/forms/schemas/ApproveDocumentSchema/ApproveDocumentSchema';

export function ApproveDocument(props: TaskProps) {
  return (
    <FlowContext.Provider value={props}>
      <ApproveDocumentSchema props={props} />
    </FlowContext.Provider>
  );
}
