import PendingTask from '../../components/_shared/pendingTask';

const GROUP_TASKS = 'my-tasks-label';
const GROUP_PROCESS = 'Prosess';
export const taskLists = [
  {
    id: 'active-pledge-information-task',
    query: 'taskType=add-pledge-information&status=pending',
    pendingTask: PendingTask,
    filter: (task) =>
      task.context?.organization?.number ||
      task.variables?.pledge?.organization?.number ||
      task.variables?.organization?.number,
    groupBy: (task) => {
      return (
        `${
          task.context?.organization?.name ||
          task.variables?.pledge?.organization?.name ||
          task.variables?.organization?.name ||
          'Organisasjon'
        } (${
          task.context?.organization?.number ||
          task.variables?.pledge?.organization?.number ||
          task.variables?.organization?.number ||
          'Ukjent'
        })` || 'tasks'
      );
    },
  },
  {
    id: 'active-tasks',
    // TODO: consider flipping this, fetch tasks with taskTypes instead of excluding all others
    query:
      'status=pending' +
      '&taskType=!%3Dadd-pledge-information' +
      '&taskType=!%3Dsign-documents' +
      '&taskType=!%3Ddocument-control-registration' +
      '&taskType=!%3Ddownload-files-for-archiving' +
      '&taskType=!%3Dhandle-signing-callback' +
      '&taskType=!%3Dstart-pledge-sub-process' +
      '&taskType=!%3Dsend-signing-tasks' +
      '&taskType=!%3Ddelete-excess-pledge' +
      '&taskType=!%3Dcreate-sign-order' +
      '&taskType=!%3Dcase-complete' +
      '&taskType=!%3Dsend-reminder-email-to-signee-manually' +
      '&taskType=!%3Dadd-organization-message' +
      '&taskType=!%3Dduplicate-documents-message' +
      '&taskType=!%3Dregister-collateral-moveables' +
      '&taskType=!%3Dcreate-files-for-archiving' +
      '&taskType=!%3Dgenerate-document' +
      '&taskType=!%3Dprep-signing-tasks' +
      '&taskType=!%3Dfetch-registration-status-moveables' +
      '&taskType=!%3Dsend-reminder-email-to-signee' +
      '&taskType=!%3Dextend-deadline-message' +
      '&taskType=!%3Dstart-add-organizations' +
      '&taskType=!%3Dstart-remove-organizations' +
      '&taskType=!%3Dadd-mocked-organizations',
    title: GROUP_TASKS,
    badgeFilter: (task) => task.status === 'pending',
    groupBy: (task) => {
      return (
        `${
          task.context?.organization?.name ||
          task.variables?.pledge?.organization?.name ||
          task.variables?.organization?.name ||
          'organization'
        } (${
          task.context?.organization?.number ||
          task.variables?.pledge?.organization?.number ||
          task.variables?.organization?.number ||
          'unknown'
        })` || 'tasks'
      );
    },
    filter: (task) =>
      task.context?.organization?.number ||
      task.variables?.pledge?.organization?.number ||
      task.variables?.organization?.number ||
      task.variables?.pledge?.pledgeId,
    map: (task, t) => {
      const documentName =
        task.context?.document?.outputFilename ||
        task.variables?.documentName ||
        task.variables?.pledge?.document?.outputFilename;
      const taskDescription = documentName ? ` (${documentName || ''})` : '';
      return {
        title: `${t(task.taskType)}${taskDescription}`,
        actionTitle: 'Start',
      };
    },
  },
  {
    id: 'registration',
    query:
      'taskType[]=register-collateral-moveables' +
      '&taskType[]=fetch-registration-status-moveables' +
      '&status=failed',
    title: 'registration',
    map: (task, t) => {
      const amount = new Intl.NumberFormat('no-NO').format(
        task.variables?.pledge?.document?.inputRaw?.amount?.value || '',
      );
      const currency =
        task.variables?.pledge?.document?.inputRaw?.amount?.currency || '';
      const documentName =
        task.variables?.pledge?.document?.outputFilename || '';
      return {
        title: `${t('pant-object')}: ${documentName} - ${amount} ${currency}`,
        actionTitle: t('details', 'Detaljer'),
        disabled: false,
      };
    },
  },
  {
    id: 'archiving',
    query: 'taskType=download-files-for-archiving&status=pending',
    title: GROUP_PROCESS,
    map: (task, t) => {
      return {
        title: t('archive-title', 'Arkivering'),
        actionTitle: 'Start',
        disabled: !task.permissions.complete,
      };
    },
  },
  {
    id: 'document-control-registration',
    query: 'taskType=document-control-registration&status=pending',
    title: GROUP_PROCESS,
    map: (task, t) => {
      return {
        title: t('document-control-registration'),
        actionTitle: 'Start',
        disabled: !task.permissions.complete,
      };
    },
  },
  {
    id: 'send-signing-tasks',
    query:
      'taskType[]=send-signing-tasks&taskType[]=start-pledge-sub-process&status=pending',
    title: GROUP_PROCESS,
    map: (task, t) => {
      return task.taskType === 'send-signing-tasks'
        ? {
            title: t('send-signing-tasks', 'Start Signering'),
            actionTitle: 'Start',
            disabled:
              !task.context?.canStartSigning || !task.permissions.trigger,
          }
        : {
            title: t('start-pledge-sub-process', 'Start Pant Subprocess'),
            actionTitle: 'Start',
            disabled: !task.permissions.trigger,
          };
    },
  },
  {
    id: 'optional-tasks',
    query:
      'taskType[]=start-add-organizations' +
      '&taskType[]=start-remove-organizations' +
      '&taskType[]=add-mocked-organizations' +
      '&status=pending',
    title: 'Valgfrie oppgaver',
    isPrimaryActionList: false,
  },
  {
    id: 'duplicates',
    query:
      'taskType[]=add-organization-message' +
      '&taskType[]=duplicate-documents-message' +
      '&taskType[]=complete-all-document-control' +
      '&status=pending&variables.isTest=true',
    title: 'Testing',
    isPrimaryActionList: false,
  },
];
