import { z } from 'zod';
import { TextField } from '../../../fields/TextField';
import { useFormContext } from 'react-hook-form';
import { useEffect } from 'react';
import { RadioButtonList } from '../../../../RadioButtonList/RadioButtonList';

export function StoragePledge({ watch }) {
  const selected = watch('fields.storage.selected');
  const { resetField } = useFormContext();

  useEffect(() => {
    resetField('fields.storage.sikkerhet_1_2_fritekst');
  }, [selected]);

  const options = [
    {
      key: 'sikkerhet_1_1_checkbox',
      defaultValue: false,
      label:
        'Pantsetters varelager i sin helhet slik det er til enhver tid, jf. panteloven § 3-9.',
      value: 'sikkerhet_1_1_checkbox',
    },
    {
      key: 'sikkerhet_1_2_checkbox',
      defaultValue: true,
      label:
        'Følgende del som driftsmessig er adskilt fra det øvrige varleger og som fremtrer som en selvstendig enhet, slik det er til enhver tid.',
      value: 'sikkerhet_1_2_checkbox',
    },
  ];
  return (
    <>
      <p className="border-b">
        Til sikkerhet for betaling av kapitalen med renter og omkosting m.m
        pantsettes:
      </p>
      <RadioButtonList
        id="fields.storage.selected"
        options={options}
      ></RadioButtonList>
      {selected === 'sikkerhet_1_2_checkbox' && (
        <TextField
          name="fields.storage.sikkerhet_1_2_fritekst"
          label="Fritekst"
        />
      )}
    </>
  );
}

StoragePledge.validationSchema = z
  .object({
    selected: z.string(),
    sikkerhet_1_2_fritekst: z.string().optional(),
  })
  .superRefine((val, ctx) => {
    if (
      val.selected === 'sikkerhet_1_2_checkbox' &&
      val.sikkerhet_1_2_fritekst.length === 0
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Du må skrive inn en fritekst',
        path: ['selected'],
      });
    }
  });

StoragePledge.defaultValues = {
  selected: 'sikkerhet_1_1_checkbox',
  sikkerhet_1_2_fritekst: '',
};
