import { useEffect, useState } from 'react';
import { getOngoingPledgeTasks } from '../api/api';

export function PhaseMapper(orgs, caseData) {
  const [pledges, setPledges] = useState(null);
  const mergePledgesAndOrgs = (orgs, caseData) => {
    const pledges = caseData?.data?.pledges;
    return orgs?.map((org) => {
      const orgNumber = org?.number;
      const pledge = pledges?.filter(
        (pledge) => pledge?.organization?.number === orgNumber,
      );
      return { ...org, pledges: pledge, queue: caseData?.data?.queue };
    });
  };

  const mergedOrgs = mergePledgesAndOrgs(orgs, caseData);
  useEffect(() => {
    const getTasks = async () => {
      const tasks = await getOngoingPledgeTasks(caseData?.flowId);
      setPledges(tasks);
    };
    getTasks();
  }, []);

  return mergedOrgs?.map((org) => {
    const hasSignees = !!org?.signees;
    const hasOngoingPledgeInformationTask =
      pledges?.filter(
        (pledge) => pledge?.context?.organization?.number === org?.number,
      )?.length > 0;
    const approvedDocuments =
      org?.pledges?.filter((pledge) => pledge?.document?.approveDocument)
        .length || 0;
    const lengthOfPledges = org?.pledges?.length || 0;

    const documentsDeclined =
      org?.pledges?.filter(
        (pledge) => pledge?.document?.approveDocument === false,
      )?.length || 0;

    // SIGNING LOGIC

    const totalDocsRequiringSignatures = org?.pledges?.filter(
      (item) => !!item?.signing,
    );
    const totalSignatures = totalDocsRequiringSignatures
      ?.map((item) =>
        item?.signing?.signStatus?.taskStatusInfo.length > 0
          ? item?.signing?.signStatus?.taskStatusInfo.length
          : 0,
      )
      ?.reduce((a, b) => a + b, 0);

    const completedSign = totalDocsRequiringSignatures?.flatMap(
      (item) =>
        item?.signing?.signStatus?.taskStatusInfo?.filter(
          (task) => task?.taskStatus === 'completed',
        ),
    );

    const requiredSignatures = totalDocsRequiringSignatures?.flatMap(
      (item) =>
        item?.signing?.signStatus?.taskStatusInfo?.filter(
          (task) => task?.taskStatus,
        ),
    );

    const howManyHasSigned = !!totalSignatures
      ? `${completedSign?.length}/${requiredSignatures?.length}`
      : '';

    // TINGLYSING LOGIC
    const hasTinglystPledge = org?.pledges?.filter(
      (pledge) =>
        pledge?.registration?.status?.status === 'tinglyst' ||
        pledge?.registration?.status?.manuallyHandled,
    );

    const howManyMadeItToTinglyst = org?.pledges?.filter(
      (item) => item?.registration,
    );

    const howManyIsTinglyst =
      howManyMadeItToTinglyst?.length > 0
        ? `${hasTinglystPledge?.length}/${howManyMadeItToTinglyst?.length}`
        : '';

    const allDocumentsHandled =
      approvedDocuments + documentsDeclined === lengthOfPledges;

    const isTinglysingStep =
      caseData?.data?.queue === 'REGISTRATION' ||
      caseData?.data?.queue === 'DOWNLOAD_FILES_FOR_ARCHIVING';

    const tinglysingComplete =
      isTinglysingStep &&
      hasTinglystPledge?.length === howManyMadeItToTinglyst?.length &&
      howManyMadeItToTinglyst?.length > 0;

    const signingComplete =
      completedSign?.length > 0 &&
      completedSign?.length === requiredSignatures?.length;
    const documentsComplete =
      !!approvedDocuments &&
      !hasOngoingPledgeInformationTask &&
      allDocumentsHandled;

    let tinglysingStatus = '';
    if (tinglysingComplete) {
      tinglysingStatus = 'Registration complete';
    } else if (signingComplete) {
      tinglysingStatus = 'Signing complete';
    } else if (documentsComplete) {
      tinglysingStatus = 'Documents complete';
    } else {
      tinglysingStatus = 'Not started';
    }

    const howManyDocumentsControlled = `${approvedDocuments}/${
      lengthOfPledges - documentsDeclined
    }`;

    const steps = [
      hasSignees,
      documentsComplete,
      signingComplete,
      tinglysingComplete,
    ];

    return {
      org,
      steps,
      status: tinglysingStatus,
      howManyHasSigned,
      howManyIsTinglyst,
      howManyDocumentsControlled,
    };
  });
}
