import { Trash2 } from 'lucide-react';
import { withTranslation } from 'react-i18next';
import { useState } from 'react';

import { deletePledgeTask, getPendingMessageTasks } from '../../api/api';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { PrimaryButton } from '@stacc/flow-ui-components';

import ModalView from './ModalView';
import { AddPledge } from '../../customer/tasks/AddPledge/AddPledge';

function PendingTask(props) {
  const [isLoading, setIsLoading] = useState(false);
  const queryClient = useQueryClient();

  const { data } = useQuery({
    queryFn: () => getPendingMessageTasks(props.flowId, 'delete-excess-pledge'),
    queryKey: ['delete-excess-pledge', props.taskId],
  });

  const { mutate } = useMutation({
    mutationFn: (id) => deletePledgeTask(id),
    onSuccess: () => {
      queryClient
        .invalidateQueries({ queryKey: ['delete-excess-pledge'] })
        .catch((err) => {
          setIsLoading(false);
        });
    },
    onMutate: () => {
      setIsLoading(true);
    },
  });

  const { t } = props;

  function deletePledge() {
    const foundFilter = data.filter(
      (task) =>
        task.variables.pledgeRequest.pledgeTmpId ===
        props.variables.pledgeRequest.pledgeTmpId,
    );
    foundFilter[0] && mutate(foundFilter[0].taskId);
  }

  const [displayCompleteTaskModal, setDisplayCompleteTaskModal] =
    useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [modalData, setModalData] = useState(null);
  const triggerModal = () => {
    setModalData(props);
    setDisplayCompleteTaskModal(true);
  };

  if (props.taskType !== 'add-pledge-information') return null;

  return (
    <div className="flex justify-between items-center px-2 py-3">
      <div className="flex items-center gap-4">
        <div className="h-4 w-4 bg-yellow-400 rounded-full relative">
          <div className="h-1 w-1 bg-white rounded-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"></div>
        </div>

        <p>{t(props.taskType)}</p>
      </div>
      <div className="flex gap-4 items-center justify-end text-red-500">
        <>
          {isLoading ? (
            <div className=" h-4 w-4 rounded-full animate-spin spinnerTwo" />
          ) : (
            <button
              disabled={!props.permissions.complete}
              onClick={() => deletePledge()}
            >
              <Trash2 color={!props.permissions.complete ? 'gray' : 'red'} />
            </button>
          )}
        </>
        <PrimaryButton
          disabled={isLoading || !props.permissions.complete}
          onClick={() => triggerModal()}
          className="bg-blue-500 hover:bg-blue-300 color-white px-8 py-1 rounded-sm text-sm text-white"
        >
          Start
        </PrimaryButton>
      </div>
      {displayCompleteTaskModal && (
        <ModalView
          title={`${props.title} for ${props?.context?.organization?.name}`}
          message={''}
          isConfirming={isSubmitting}
          onConfirm={() => null}
          props={props}
          component={AddPledge(props)}
          onAbort={() => setDisplayCompleteTaskModal(false)}
          onClose={() => setDisplayCompleteTaskModal(false)}
        />
      )}
    </div>
  );
}

export default withTranslation('caseManagement')(PendingTask);
