import React, { useContext } from 'react';
import { TaskProps } from '../types/TaskProps';

type FlowContextType = TaskProps & {
  isReevaluating?: boolean;
  errors?: string[];
};

const FlowContext = React.createContext<FlowContextType | null>(null);

function useFlowContext() {
  return useContext(FlowContext) as {
    props: {
      t: (
        key: string,
        fallback?: string,

        variables?: {
          [key: string]: any;
        },
      ) => string;
    };
  } & FlowContextType;
}

export { useFlowContext, FlowContext };
