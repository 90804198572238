import React from 'react';
import { format, parseISO } from 'date-fns';
import type { Stakeholder } from '../../types/Stakeholder';
import { queueNames } from '../caseManagerConfig/queues/queueNames';

type QueueItemProps = {
  path: string;
  flow: any;
  t: (key: string, fallback?: string) => string;
};
export function QueueItem({ path, flow, t }: QueueItemProps) {
  const { flowDefinitionId, flowId, data, createdAt, assignee, referenceId } =
    flow;
  const createdAtDate = format(parseISO(createdAt), 'dd.MM.yyyy');
  const isActive =
    window?.location?.pathname ===
    `/inbox/${path}/${flowDefinitionId}/${flowId}`;

  const companyName = data?.companyName ?? t('no-company-name', '-- -- --');
  return (
    <a
      aria-current={'page'}
      href={`/inbox/${path}/${flowDefinitionId}/${flowId}`}
      className={` queue-link flex flex-col px-3 py-2  ${
        isActive
          ? 'border-l-4 border-blue-300 bg-primary-100'
          : 'border-l-4 border-opacity-0 bg-primary-50 shadow'
      }`}
    >
      <div>
        <div className={'grid grid-cols-queue-item gap-1'}>
          <p className={'text-lg text-gray-700'}>
            {!!data?.stakeholders
              ? data.stakeholders
                  ?.map(
                    (stakeholder: Stakeholder) =>
                      `${stakeholder.fullName ?? stakeholder?.email} `,
                  )
                  .join(' & ')
              : ''}
          </p>
        </div>
        <div className={'mt-1 flex flex-col gap-1 text-xs text-gray-500'}>
          <div className="flex w-full items-center justify-between">
            <p>{referenceId}</p>
            <p className={'text-right'}>{t(queueNames[data.queue])}</p>{' '}
          </div>
          <div className="flex w-full items-center justify-between">
            <p className=" font-bold">{companyName}</p>
            <p />
          </div>
          <div className="flex w-full items-center justify-between">
            <p>{assignee?.name ? assignee?.name : t('no-case-worker')}</p>
            <p className={'text-right'}>{createdAtDate}</p>
          </div>
        </div>
      </div>
    </a>
  );
}
