import axios from 'axios';

export async function getAttachments(token, flowId, attachmentKey) {
  try {
    const { data } = await axios.get(
      `/api/flow/flows/${flowId}/attachments/${attachmentKey}/download`,
      {
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    const file = new Blob([data], { type: 'application/pdf' });
    return URL.createObjectURL(file);
  } catch (e) {
    throw new Error(e);
  }
}
export async function triggerMessageTask(taskId) {
  try {
    const { data } = await axios.post(`/api/flow/tasks/${taskId}/trigger`);
    return data;
  } catch (e) {
    throw new Error(e);
  }
}

export async function triggerMessageTaskWithBody(taskId, payload) {
  try {
    const { data } = await axios.post(
      `/api/flow/tasks/${taskId}/trigger`,
      payload,
    );
    return data;
  } catch (e) {
    throw new Error(e);
  }
}

export async function complete(taskId, payload) {
  try {
    const { data } = await axios.post(
      `/api/flow/tasks/${taskId}/complete`,
      payload,
    );
    return data;
  } catch (e) {
    throw new Error(e);
  }
}

export async function getPendingMessageTasks(flowId, taskType) {
  try {
    const { data } = await axios.get(
      `/api/flow/tasks?flowId=${flowId}&taskType=${taskType}&status=pending`,
    );
    return data;
  } catch (e) {
    throw new Error(e);
  }
}

export async function triggerSendManualReminderByEmail(
  flowId: string,
  organizationId: string,
  email: string,
) {
  try {
    const { data } = await axios.get(
      `/api/flow/tasks?flowId=${flowId}&status=pending&taskType=send-reminder-email-to-signee-manually&variables.organizationId=${organizationId}&variables.signee.email=${email}`,
    );
    const task = data[0];
    return triggerMessageTask(task.taskId);
  } catch (e) {
    console.error('Error sending reminder', e);
    throw new Error(e);
  }
}

export async function triggerSendManualReminderById(
  flowId: string,
  organizationId: string,
  signeeId: number,
) {
  try {
    const { data } = await axios.get(
      `/api/flow/tasks?flowId=${flowId}&status=pending&taskType=send-reminder-email-to-signee-manually&variables.organizationId=${organizationId}&variables.signee.id=${signeeId}`,
    );
    const task = data[0];
    return triggerMessageTask(task.taskId);
  } catch (e) {
    console.error('Error sending reminder', e);
    throw new Error(e);
  }
}

export async function getOngoingPledgeTasks(flowId) {
  try {
    const { data } = await axios.get(
      `/api/flow/tasks?flowId=${flowId}&taskType=add-pledge-information&status=pending`,
    );
    return data;
  } catch (e) {
    throw new Error(e);
  }
}

export async function getExtendedPledgeTasks(flowId) {
  try {
    const { data } = await axios.get(
      `/api/flow/tasks?flowId=${flowId}&taskType=extend-deadline-message&status=pending`,
    );
    return data;
  } catch (e) {
    throw new Error(e);
  }
}

export async function getFlows(token, queryParams = '') {
  try {
    const { data } = await axios.get(`/api/flow/flows${queryParams}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (e) {
    throw new Error(e);
  }
}

export async function deletePledgeTask(taskId) {
  return triggerMessageTask(taskId);
}

export async function createPledge(payload) {
  try {
    const data = complete(payload.taskId, payload.payload);
    return data;
  } catch (e) {
    throw new Error(e);
  }
}
