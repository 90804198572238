import { useQuery } from '@tanstack/react-query';
import { Button } from '../../components/_shared/Button/Button';
import { LoadingSpinner } from '../../components/_shared/Spinner/Spinner';
import { getAttachments } from '../../api/api';
import { withTranslation } from 'react-i18next';

function ArchiveTab({ flow, user, t }) {
  const access_token = user.access_token;
  const flowId = flow.flowId;

  const { data, isFetching, isError, error, refetch } = useQuery({
    queryKey: [flowId],
    queryFn: () => getAttachments(access_token, flowId, 'caseSummary'),
  });

  if (isFetching) {
    return (
      <div className="flex items-center justify-center h-full flex-col">
        <LoadingSpinner />
        <p>{t('loading-document', 'Laster inn dokument')}...</p>
      </div>
    );
  }

  if (isError) {
    return (
      <div className="flex items-center justify-start mt-8 gap-8 flex-col">
        <p>
          {t(
            'error-occured',
            'Det oppstod en feil, vennligst lukk vinduet eller prøv igjen.',
          )}
        </p>{' '}
        <div className="flex gap-4">
          <Button onClick={() => refetch()}>
            {t('try-again', 'Prøv igjen')}
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="grid grid-cols-4">
      <iframe
        className=" min-h-screen w-full col-span-3"
        title="arkiv"
        src={data}
      />
      <div className="ml-4 flex flex-col gap-2">
        <a href={data} download="arkiv.pdf">
          <Button>{t('download', 'Last ned')}</Button>
        </a>
      </div>
    </div>
  );
}

export default withTranslation()(ArchiveTab);
