import { z } from 'zod';
import { TextField } from '../../../fields/TextField';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { RadioButtonList } from '../../../../RadioButtonList/RadioButtonList';

export function FishingToolsPledge({ watch }) {
  const selected = watch('fields.fishingTools.selected');
  const { resetField } = useFormContext();
  useEffect(() => {
    resetField('fields.fishingTools.sikkerhet_1_2_fritekst');
  }, [selected]);

  const options = [
    {
      key: 'sikkerhet_1_1_checkbox',
      defaultValue: false,
      label:
        'Pantsetters løsøre som brukes eller er bestemt til bruk i næringsvirksomhet med fiske-eller fangstfartøy, men som ikke er tilbehør til et fartøy, jf. panteloven § 3-10. Pantsettelsen gjelder løsøret i sin helhet.',
      value: 'sikkerhet_1_1_checkbox',
    },
    {
      key: 'sikkerhet_1_2_checkbox',
      defaultValue: true,
      label:
        'Pantsetters løsøre som brukes eller er bestemt til bruk i næringsvirksomhet med fiske- eller fangstfartøy, men som ikke er tilbehør til et fartøy, jf. panteloven § 3-10. Pantsettelsen gjelder løsøret i sin helhet knyttet til følgende del av næringsvirksomheten som driftsmessig er adskilt fra det øvrige.',
      value: 'sikkerhet_1_2_checkbox',
    },
  ];

  return (
    <>
      <p className="border-b">
        Til sikkerhet for betaling av kapitalen med renter og omkostinger m.m
        pantsettes:
      </p>
      <RadioButtonList
        id="fields.fishingTools.selected"
        options={options}
      ></RadioButtonList>

      {selected === 'sikkerhet_1_2_checkbox' && (
        <TextField
          name="fields.fishingTools.sikkerhet_1_2_fritekst"
          label="Fritekst"
        />
      )}
    </>
  );
}
FishingToolsPledge.validationSchema = z
  .object({
    selected: z.string(),
    sikkerhet_1_2_fritekst: z.string().optional(),
  })
  .superRefine((val, ctx) => {
    if (
      val.selected === 'sikkerhet_1_2_checkbox' &&
      val.sikkerhet_1_2_fritekst.length === 0
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Legg til fritekst',
        path: ['sikkerhet_1_2_fritekst'],
      });
    }
  });

FishingToolsPledge.defaultValues = {
  selected: 'sikkerhet_1_1_checkbox',
  sikkerhet_1_2_fritekst: '',
};
