import { z } from 'zod';
import { TextField } from '../../../fields/TextField';
import { useFormContext } from 'react-hook-form';
import { useEffect } from 'react';
import { RadioButtonList } from '../../../../RadioButtonList/RadioButtonList';

export function FactoringPledge({ watch }) {
  const freeText = watch('fields.factoring.selectedThree');
  const { resetField } = useFormContext();
  useEffect(() => {
    resetField('fields.factoring.avtale_3_3_fritekst');
  }, [freeText]);

  const optionsOne = [
    {
      key: 'avtale_1_1_checkbox',
      defaultValue: false,
      label: 'Avhendelse av enkle pengekrav i næringsvirksomheten.',
      value: 'avtale_1_1_checkbox',
    },
    {
      key: 'avtale_1_2_checkbox',
      defaultValue: true,
      label:
        'Avhendelse i sikringsøyemed av enkle pengekrav i næringsvirksomheten.',
      value: 'avtale_1_2_checkbox',
    },
    {
      key: 'avtale_1_3_checkbox',
      defaultValue: true,
      label: 'Pantsettelse av enkle pengekrav i næringsvirksomheten.',
      value: 'avtale_1_3_checkbox',
    },
  ];

  const optionsTwo = [
    {
      key: 'avtale_2_1_checkbox',
      label: 'Har',
      value: 'avtale_2_1_checkbox',
    },
    {
      key: 'avtale_2_2_checkbox',
      label: 'Får',
      value: 'avtale_2_2_checkbox',
    },
    {
      key: 'avtale_2_3_checkbox',
      label: 'Har og får',
      value: 'avtale_2_3_checkbox',
    },
  ];

  const optionsThree = [
    {
      key: 'avtale_3_1_checkbox',
      defaultValue: false,
      label: 'Avtalen gjelder hele næringsvirksomheten.',
      value: 'avtale_3_1_checkbox',
    },
    {
      key: 'avtale_3_2_checkbox',
      defaultValue: true,
      label:
        'Avtalen er begrenset til følgende særskilte del av næringsvirksomheten:',
      value: 'avtale_3_2_checkbox',
    },
  ];
  return (
    <>
      <p className=" border-b border-gray-400 pb-2">
        Partene har inngått følgende avtale om factoring, jf. panteloven § 4-10{' '}
      </p>
      <div className="p-2 bg-blue-100 grid gap-4">
        <RadioButtonList
          id="fields.factoring.selectedOne"
          options={optionsOne}
        ></RadioButtonList>
      </div>
      <p className=" border-b border-gray-400 pb-2">
        Avtalen omfatter krav den næringsdrivende...
      </p>
      <div className="p-2 bg-blue-100 flex items-start text-left justify-start">
        <RadioButtonList
          id="fields.factoring.selectedTwo"
          options={optionsTwo}
        ></RadioButtonList>
      </div>
      <p className=" border-b border-gray-400 pb-2">Annet</p>
      <RadioButtonList
        id="fields.factoring.selectedThree"
        options={optionsThree}
      ></RadioButtonList>
      {freeText === 'avtale_3_2_checkbox' && (
        <TextField
          name="fields.factoring.avtale_3_3_fritekst"
          label="Fritekst"
        />
      )}
    </>
  );
}

FactoringPledge.validationSchema = z
  .object({
    selectedOne: z.string(),
    selectedTwo: z.string(),
    selectedThree: z.string(),
    avtale_3_3_fritekst: z.string().optional(),
  })
  .superRefine((val, ctx) => {
    if (
      val.selectedThree === 'avtale_3_2_checkbox' &&
      val.avtale_3_3_fritekst.length === 0
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Legg til fritekst',
        path: ['avtale_3_3_fritekst'],
      });
    }
  });

FactoringPledge.defaultValues = {
  selectedOne: 'avtale_1_3_checkbox',
  selectedTwo: 'avtale_2_3_checkbox',
  selectedThree: 'avtale_3_1_checkbox',
  avtale_3_3_fritekst: '',
};
