import { useFormContext } from 'react-hook-form';
import { TextField } from '../../../fields/TextField';
import { useEffect } from 'react';
import { z } from 'zod';
import { RadioButtonList } from '../../../../RadioButtonList/RadioButtonList';

export function MotorPledge({ watch }) {
  const { resetField } = useFormContext();

  const selectedMotor = watch('fields.motor.selected');

  useEffect(
    () => resetField('fields.motor.sikkerhet_1_2_fritekst'),
    [selectedMotor],
  );

  const options = [
    {
      key: 'sikkerhet_1_1_checkbox',
      defaultValue: false,
      label:
        'Pantsetters samlede motorvogner. Driver pantsetter entreprenervirksomhet omfattes ogsa flyttbare anleggsmaskiner, jf panteloven § 3-8.',
      value: 'sikkerhet_1_1_checkbox',
    },
    {
      key: 'sikkerhet_1_2_checkbox',
      defaultValue: true,
      label:
        'Pantsetters samlede motorvogner i del av næringsvirksomheten. Driver pantsetter entreprenervirksomhet omfattes ogs flyttbare anleggsmaskiner, jf panteloven § 3-8.',
      value: 'sikkerhet_1_2_checkbox',
    },
  ];

  return (
    <div className=" grid gap-4">
      <p className="border-b">
        Til sikkerhet for betaling av kapitalen med renter og omkostninger m.m.
        pantsettes:
      </p>
      <RadioButtonList
        id="fields.motor.selected"
        options={options}
      ></RadioButtonList>
      {selectedMotor === 'sikkerhet_1_2_checkbox' && (
        <TextField
          name="fields.motor.sikkerhet_1_2_fritekst"
          label="Fritekst"
        />
      )}
    </div>
  );
}

MotorPledge.validationSchema = z.object({
  selected: z.string(),
  sikkerhet_1_2_fritekst: z.string().optional(),
});

MotorPledge.defaultValues = {
  selected: 'sikkerhet_1_1_checkbox',
  registeredVehicles: [],
  unregisteredVehicles: [],
};

// MAX 45 unreg
// MAX 50 reg
