import { FlowContext } from '../../../hooks/useFlowContext';
import { TaskProps } from '../../../types/TaskProps';
import { ExtendSigningDeadlineSchema } from './ExtendSigningDeadlineSchema';

export function ExtendSigningDeadline(props: {
  data: TaskProps;
  t: any;
  setOpen;
  allMessageTasks;
  currentTasks;
  signee;
}) {
  return (
    <FlowContext.Provider value={props.data}>
      <ExtendSigningDeadlineSchema
        t={props.t}
        allMessageTasks={props.allMessageTasks}
        currentTasks={props.currentTasks}
        flowProps={props.data}
        setOpen={props.setOpen}
        signee={props.signee}
      />
    </FlowContext.Provider>
  );
}
