import { FlowContext } from '../../../hooks/useFlowContext';
import { TaskProps } from '../../../types/TaskProps';
import { GetEndDate } from '../../../utils/parseDuration';
import { Button } from '../../../components/_shared/Button/Button';
import { useMutation } from '@tanstack/react-query';
import {
  triggerSendManualReminderByEmail,
  triggerSendManualReminderById,
} from '../../../api/api';
import { Check, LoaderCircle, X } from 'lucide-react';
import { useState } from 'react';
import { WarningMessage } from '../../../components/_shared/ErrorMessage/WarningMessage';
import { useTranslation } from 'react-i18next';

export function SendReminderEmail(props: {
  data: TaskProps;
  t: any;
  setOpen;
  refetch;
  signee;
}) {
  const { i18n } = useTranslation();
  const { t } = i18n;
  const flowProps: any = props.data;
  const setOpen = props.setOpen;
  const signee = props.signee;
  const [disabled, setDisabled] = useState(false);

  const { isLoading, isError, isSuccess, mutate, reset, status } = useMutation(
    sendReminder,
    {
      onMutate: () => {
        setDisabled(true);
      },
      onSettled: () => {
        setTimeout(() => {
          reset();
        }, 3000);
      },
      onSuccess: () => {
        setTimeout(() => {
          setOpen(false);
        }, 1000);
      },
    },
  );

  async function sendReminder() {
    const flowId = flowProps.flowId;
    const organizationId = flowProps.variables.organizationId;

    if (signee.id !== undefined) {
      await triggerSendManualReminderById(flowId, organizationId, signee.id);
    } else {
      await triggerSendManualReminderByEmail(
        // for backwards compatibility
        flowId,
        organizationId,
        signee.email,
      );
    }
  }

  async function handleSendReminder() {
    mutate();
  }

  const date = GetEndDate(
    flowProps?.variables?.signingStartTime,
    flowProps?.variables?.deadline,
  );
  const formatCurrentDeadline = `${date.getDate()}/${
    date.getMonth() + 1
  }/${date.getFullYear()}`;

  const lastChanged = signee.reminderSent?.timestamp;
  const changeUser = signee.reminderSent?.sentBy;
  const automaticReminderSent = changeUser === 'flow';
  const changeUserPretty = !automaticReminderSent
    ? `${t('by')} ${changeUser}`
    : '';
  const lastChangedPretty = new Date(lastChanged).toLocaleDateString(
    i18n.language === 'no' ? 'no-NO' : 'en-EN',
    {
      dateStyle: 'medium',
    },
  );
  return (
    <FlowContext.Provider value={props.data}>
      <div className="p-8 flex flex-col justify-between gap-4">
        <div className="border-containerBorder p-4 bg-containerBackground rounded border flex flex-col gap-4">
          <p className="font-semibold">
            {t('send-reminder-signing', 'Send påminnelse om signering')}
          </p>
          <div className="flex flex-col">
            <dl className="flex gap-2">
              <dt>{t('signee', 'Signatar')}:</dt>
              <dd>{signee?.name}</dd>
            </dl>
            <dl className="flex gap-2">
              <dt>{t('original-deadline', 'Opprinnelig signeringsfrist')}:</dt>
              <dd>{formatCurrentDeadline}</dd>
            </dl>
          </div>
        </div>
        {lastChanged && (
          <WarningMessage
            message={`${
              automaticReminderSent
                ? t('reminder-sent-automatic')
                : t('reminder-sent')
            }: ${lastChangedPretty} ${changeUserPretty}`}
          ></WarningMessage>
        )}
        <div className="flex gap-2">
          <Button
            primary={true}
            onClick={handleSendReminder}
            disabled={disabled}
            className="flex items-center gap-2"
          >
            {isLoading
              ? t('loading', 'Laster...')
              : t('send-reminder', 'Send påminnelse')}
            {isLoading && <LoaderCircle className="h-5 w-5 animate-spin" />}
          </Button>
          <Button primary={false} onClick={() => setOpen(false)}>
            Avbryt
          </Button>
        </div>
        {isError && (
          <div className="flex gap-2 items-center border-red-500 p-4 bg-red-50 rounded border">
            {t('send-reminder-error', 'Sending av påminnelse feilet.')}
            <X className="h-5 w-5 text-red-500" />
          </div>
        )}
        {isSuccess && (
          <div className="flex gap-2 items-center border-green-500 p-4 bg-green-50 rounded border">
            {t('send-reminder-success', 'Påminnelse sendt!')}
            <Check className="h-5 w-5 text-green-500" />
          </div>
        )}
      </div>
    </FlowContext.Provider>
  );
}
