import { FormProvider, useForm } from 'react-hook-form';
import { Button } from '../../../components/_shared/Button/Button';
import { OrgSelect } from '../../../components/_shared/forms/fields/OrgSelect';
import { useState } from 'react';
import clsx from 'clsx';
import { CustomAmount } from '../../../components/_shared/forms/fields/CustomAmount';
import { zodResolver } from '@hookform/resolvers/zod';
import z from 'zod';

export function StartPledge({ flowProps }) {
  const listOfOrgsWithSignees = flowProps.flow.data.organizations.filter(
    (org) => org.signees,
  );
  const { close, trigger, t } = flowProps;
  const defaultOrg = flowProps?.flow?.data?.organizations[0];
  const method = useForm({
    resolver: zodResolver(
      z
        .object({
          organization: z.object({
            value: z.object({
              number: z.string(),
              name: z.string(),
            }),
            label: z.string(),
            id: z.string(),
          }),
          customAmount: z
            .string()
            .transform((val) => Number(val))
            .optional(),
        })
        .superRefine((val, ctx) => {
          if (val.customAmount.toString().match(/^[0-9]+$/) === null) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: t(
                'custom-amount-type-error',
                'Antallet må være et tall',
              ),
              path: ['customAmount'],
            });
          }

          if (val.customAmount > 50) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: t(
                'custom-amount-error',
                'Antallet må være mindre enn 50',
              ),
              path: ['customAmount'],
            });
          }
        }),
    ),
    defaultValues: {
      organization: {
        value: {
          ...defaultOrg,
        },
        label: defaultOrg?.name
          ? `${defaultOrg?.number} ${defaultOrg?.name}`
          : defaultOrg?.number,
        id: defaultOrg?.number,
      },
      customAmount: '0',
    },
  });

  const { handleSubmit, watch } = method;
  const [error, setError] = useState(null);
  const [selected, setSelected] = useState(2);
  const onSubmit = (data) => {
    if (data.organization.value) {
      trigger({
        organization: data.organization.value,
        amount: data?.customAmount > 0 ? data?.customAmount : selected,
      });
    } else {
      setError(true);
    }
  };

  const customAmountSet = watch('customAmount');

  const select = [1, 2, 3, 4, 5];
  return (
    <div className="p-4">
      {listOfOrgsWithSignees.length > 0 ? (
        <FormProvider {...method}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col gap-6">
              <div className="grid gap-4">
                <OrgSelect
                  flowProps={flowProps}
                  label={t('select-org')}
                  name="organization"
                />
                <label aria-labelledby="select">
                  {t('select-amount-of-processes')}
                </label>
                <div className="flex items-center">
                  {select.map((item) => {
                    return (
                      <button
                        onClick={() => setSelected(item)}
                        id="select"
                        type="button"
                        disabled={Number(customAmountSet) > 0}
                        className={clsx(
                          Number(customAmountSet) > 0 ? 'opacity-25' : '',
                          'p-4 border border-gray-300  hover:text-white',
                          selected === item
                            ? 'bg-blue-400 text-white'
                            : 'hover:border-blue-200 hover:bg-blue-200',
                        )}
                      >
                        {item}
                      </button>
                    );
                  })}
                </div>
                <div className="flex flex-col items-start">
                  <CustomAmount
                    label={t(
                      'add-custom-amount-label',
                      'Eller legg til en egendefinert mengde',
                    )}
                    name="customAmount"
                  />
                </div>
                {error && (
                  <p className="text-red-500">
                    {t('select-org', 'Vennligst velg en organisasjon')}
                  </p>
                )}
              </div>
              <Button type="submit">{t('create-document')}</Button>
            </div>
          </form>
        </FormProvider>
      ) : (
        <div className="flex flex-col items-center justify-between gap-6">
          <p>
            {t(
              'no-configured-signees-found',
              ' Vi ser at det ikke er konfigurert noen signatarer. Vennligst gjør dette før du fortsetter.',
            )}
          </p>
          <Button onClick={() => close()}>{t('close, Lukk')}</Button>
        </div>
      )}
    </div>
  );
}
