import clsx from 'clsx';

type ButtonProps = {
  children?: any;
  primary?: boolean;
  type?: any;
  disabled?: boolean;
  onClick?: any;
  className?: string;
};

export function Button({
  children,
  primary = true,
  type,
  disabled,
  onClick,
  className,
}: ButtonProps) {
  return (
    <button
      type={type}
      disabled={disabled}
      onClick={() => onClick && onClick()}
      className={clsx(
          disabled
          ? 'bg-gray-300 text-white border-2 border-transparent rounded-md px-8'
          : primary
          ? 'bg-blue-500 text-white border-2 border-transparent rounded-md px-8 hover:bg-buttonHover'
          : 'bg-white border-blue-100 border-2 text-blue-500 rounded-md px-8',
        'p-2 w-max',
        className,
      )}
    >
      {children}
    </button>
  );
}
