import { useFormConnect } from '../../../../hooks/useFormConnect';
import { Combobox } from '../../Inputs/Combobox/Comboxbox';

type OrgSelectProps = {
  name: string;
  flowProps: any;
  label?: string;
};

export const OrgSelect = ({ name, flowProps, label = '' }: OrgSelectProps) => {
  const props = useFormConnect(name);
  const orgs = flowProps.flow.data.organizations.filter((org) => org.signees);
  const options = orgs.map((org) => {
    return {
      value: {
        ...org,
      },
      label: org.name ? `${org.number} ${org.name}` : org.number,
      id: org.number,
    };
  });
  return <Combobox options={options} label={label} {...props} />;
};
