import clsx from 'clsx';
import { Check, ChevronDownIcon } from 'lucide-react';
import { useState } from 'react';
import { NO, GB, DK } from 'country-flag-icons/react/3x2';
import { FadeIn } from '../../components/transitions/FadeIn';

export function LanguageSelect({}) {
  function setLanguage(lang) {
    localStorage.setItem('language', lang);
    window.location.reload();
  }

  const [isOpen, setIsOpen] = useState(false);

  const selectedLanguage = localStorage.getItem('language') || 'no';
  const options = [
    {
      value: 'en',
      label: 'English',
      icon: <GB />,
      isSelected: selectedLanguage === 'en',
      shouldShow: true,
    },
    {
      value: 'no',
      label: 'Norsk',
      icon: <NO />,
      isSelected: selectedLanguage === 'no',
      shouldShow: true,
    },
    // {
    //   value: 'dk',
    //   label: 'Dansk',
    //   icon: <DK />,
    //   isSelected: selectedLanguage === 'dk',
    //   shouldShow: true,
    // },
    {
      value: 'dev',
      label: 'Dev',
      icon: '🚀',
      isSelected: selectedLanguage === 'dev',
      shouldShow: process.env.REACT_APP_ENV === 'development',
    },
  ];

  const languageMapper = (lang) => {
    switch (lang) {
      case 'en':
        return (
          <span className="flex flex-row gap-4 items-center">
            <span className="w-4">
              <GB />
            </span>
            English
          </span>
        );
      case 'no':
        return (
          <span className="flex flex-row gap-4 items-center">
            <span className="w-4">
              <NO />
            </span>
            Norsk
          </span>
        );
      case 'dk':
        return (
          <span className="flex flex-row gap-4 items-center">
            <span className="w-4">
              <DK />
            </span>
            Dansk
          </span>
        );
      case 'dev':
        return '🚀 Developer Mode';
      default:
        return (
          <span className="flex flex-row gap-4 items-center">
            <span className="w-4">
              <NO />
            </span>
            Norsk
          </span>
        );
    }
  };

  return (
    <div className="relative w-full" onMouseLeave={() => setIsOpen(false)}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className=" bg-blue-50 shadow-sm hover:shadow-md w-full p-2 px-4 rounded-md justify-between flex gap-4 items-center"
      >
        {languageMapper(selectedLanguage)} <ChevronDownIcon size={12} />
      </button>
      {isOpen && (
        <FadeIn>
          <div className="absolute bg-white min-w-full shadow-lg rounded-md">
            {options.map((option) => {
              if (option.shouldShow)
                return (
                  <button
                    key={option.value}
                    className={clsx(
                      ' hover:bg-blue-100 p-2 border-b-2 border-gray-200 w-full flex justify-between items-center',
                      option.isSelected && 'bg-blue-100',
                    )}
                    onClick={() => setLanguage(option.value)}
                  >
                    <span className="flex flex-row items-center gap-4">
                      <span className="w-4"> {option.icon}</span> {option.label}
                    </span>
                    <span> {option.isSelected && <Check size={12} />}</span>
                  </button>
                );
            })}
          </div>
        </FadeIn>
      )}
    </div>
  );
}
