import { FlowContext } from '../../../hooks/useFlowContext';
import { TaskProps } from '../../../types/TaskProps';
import { RemoveOrganizationsSchema } from '../RemoveOrganizations/RemoveOrganizationsSchema';

export function RemoveOrganizations(props: TaskProps) {
  return (
    <FlowContext.Provider value={props}>
      <RemoveOrganizationsSchema flowProps={props} />
    </FlowContext.Provider>
  );
}
