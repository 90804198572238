import { Button } from '../../../components/_shared/Button/Button';

export function StartSigningSchema({ flowProps }) {
  const { trigger, t } = flowProps;

  return (
    <div className="flex items-center justify-start flex-col py-8 gap-8">
      <p>{t('start-signing-process')}</p>
      <div className=" flex gap-4 items-center">
        <Button onClick={() => trigger({ formData: null })}>Start</Button>
      </div>
    </div>
  );
}
