import { useFormConnect } from '../../../../hooks/useFormConnect';
import { Select } from '../../Select/Select';

export const Currency = ({ name, label }) => {
  const props = useFormConnect(name);
  const options = [
    {
      value: 'NOK',
      label: 'NOK',
    },
    {
      value: 'SEK',
      label: 'SEK',
    },
    {
      value: 'DKK',
      label: 'DKK',
    },
    {
      value: 'USD',
      label: 'USD',
    },
    {
      value: 'GBP',
      label: 'GBP',
    },
    {
      value: 'EUR',
      label: 'EUR',
    },
  ];
  return <Select {...props} options={options} id={name} label={label} />;
};
