import { createRoot } from 'react-dom/client';
import * as serviceWorker from './serviceWorker';
import App from './App';
import "@stacc/flow-ui-style/dist/global.css";
import './index.css';

const root = createRoot(document.getElementById('root'));
root.render(<App/>);

serviceWorker.unregister();
