import { TextField } from '../../../fields/TextField';
import { z } from 'zod';
import { useFormContext } from 'react-hook-form';
import { useEffect } from 'react';
import { RadioButtonList } from '../../../../RadioButtonList/RadioButtonList';

export function AgriculturePledge({ watch }) {
  const selected = watch('fields.agriculture.selected');
  const { resetField } = useFormContext();
  useEffect(() => {
    resetField('fields.agriculture.sikkerhet_1_2_fritekst');
  }, [selected]);

  const options = [
    {
      key: 'sikkerhet_1_1_checkbox',
      defaultValue: false,
      label:
        'Pantsetters løsøre som brukes eller er bestemt til bruk i næringsvirksomhet i landbruket, men som ikke er tilbehør til fast eiendom, og varer som produseres i virksomheten, jf. panteloven §3-9. Pantsettelsen gjelder løsøret i sin helhet slik det er til enhver tid.',
      value: 'sikkerhet_1_1_checkbox',
    },
    {
      key: 'sikkerhet_1_2_checkbox',
      defaultValue: true,
      label:
        'Pantsetters løsøre som brukes eller er bestemt til bruk i næringsvirksomhet i landbruket, men som ikke er tilbehør til fast eiendom, og varer som produseres i virksomheten, jf. panteloven §3-9. Pantsettelsen gjelder loseret i sin helhet knyttet til følgende del av næringsvirksomheten som driftsmessig er adskilt fra det øvrige.',
      value: 'sikkerhet_1_2_checkbox',
    },
  ];
  return (
    <>
      <p className="border-b">
        Til sikkerhet for betaling av kapitalen med renter og omkostninger m.m
        pantsettes:
      </p>
      <RadioButtonList
        id="fields.agriculture.selected"
        options={options}
      ></RadioButtonList>

      {selected === 'sikkerhet_1_2_checkbox' && (
        <TextField
          name="fields.agriculture.sikkerhet_1_2_fritekst"
          label="Fritekst"
        />
      )}
    </>
  );
}
AgriculturePledge.validationSchema = z
  .object({
    selected: z.string(),
    sikkerhet_1_2_fritekst: z.string().optional(),
  })
  .superRefine((val, ctx) => {
    if (
      val.selected === 'sikkerhet_1_2_checkbox' &&
      val.sikkerhet_1_2_fritekst.length === 0
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Fritekst må fylles ut',
        path: ['selected'],
      });
    }
  });

AgriculturePledge.defaultValues = {
  selected: 'sikkerhet_1_1_checkbox',
  sikkerhet_1_2_fritekst: '',
};
