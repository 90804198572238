import { useState } from 'react';
import { CircleCheckBig, ClipboardCopy, Copy } from 'lucide-react';

export const CustomClipboard = ({ text, t, label = '' }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(text);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  return (
    <div
      className="flex items-center cursor-pointer hover:text-blue-500"
      title={t('copy-to-clipboard-explanation', 'Kopier signeringslenken')}
      onClick={handleCopy}
    >
      {label && <span className="underline hover:text-blue-500 pr-1">{label}</span>}
      {copied ? (
        <CircleCheckBig className="h-4 w-4 text-green-500 " />
      ) : (
        <Copy className="h-4 w-4" />
      )}
    </div>
  );
};
