import { BellIcon } from 'lucide-react';
import { useState } from 'react';
import { NotificationsList } from './NotificationsList';
import { useQuery } from '@tanstack/react-query';
import { getFlows } from '../../../api/api';
import { PopIn } from '../../../components/transitions/PopIn';

export function NotificationsButton({ user }) {
  const token = user?.access_token;
  const queryParam = `?status=active&hasIncidents=true&flowDefinitionId=case&view=incidents`;
  const [open, setOpen] = useState(false);

  const { data, isFetching, refetch } = useQuery({
    queryKey: ['getFlows'],
    queryFn: async () => await getFlows(token, queryParam),
    refetchInterval: 20000,
  });

  function closeAndUpdateTimestamp() {
    setOpen(false);
    localStorage.setItem('readTimeStamp', new Date() as any);
    refetch();
  }

  function setRead() {
    localStorage.setItem('readTimeStamp', new Date() as any);
  }

  if (data?.length > 0) {
    const sortNotificationsByDate = data.sort(
      (a, b) =>
        new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime(),
    );
    const getTimeFromLocalStorage = localStorage?.getItem('readTimeStamp');

    const readTimeStamp = getTimeFromLocalStorage
      ? new Date(getTimeFromLocalStorage).toISOString()
      : new Date().toISOString();

    const unreadNotifications = sortNotificationsByDate.filter(
      (notification) => notification.updatedAt > readTimeStamp,
    );

    const readNotifications = sortNotificationsByDate.filter(
      (notification) => notification.updatedAt < readTimeStamp,
    );
    return (
      <PopIn>
        <button
          disabled={isFetching}
          onClick={() => setOpen(!open)}
          className="flex relative gap-1 shadow-sm hover:shadow-md transition-all font-bold items-center text-red-600 bg-blue-100 border-4 border-white px-2 py-1 rounded-lg"
        >
          <BellIcon size={18} />
          <>
            {unreadNotifications.length > 0 && (
              <span>{unreadNotifications.length}</span>
            )}
            <div className="absolute top-8  z-50 right-0 text-black">
              {open && (
                <NotificationsList
                  notifications={data}
                  unreadNotifications={unreadNotifications}
                  readNotifications={readNotifications}
                  onClose={() => closeAndUpdateTimestamp()}
                  setRead={() => setRead()}
                />
              )}
            </div>
          </>
        </button>
      </PopIn>
    );
  }
  return null;
}
