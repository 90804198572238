import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { Checkbox } from '../../fields/Checkbox';
import { TextField } from '../../fields/TextField';
import { Button } from '../../../Button/Button';
import { zodResolver } from '@hookform/resolvers/zod';
import z from 'zod';
import AddCustomSigneeSchema from '../AddCustomSigneeSchema/AddCustomSigneeSchema';
import { useState } from 'react';
import { WarningMessage } from '../../../ErrorMessage/WarningMessage';

export function ConfigureSigneesSchema({ flowProps }) {
  const { save, task, complete, t } = flowProps;
  const enkWarning = t(
    'enk-warning',
    'OBS! Personene listet har verv i selskapet, men ikke nødvendigvis signeringsrettigheter. Vennligst påse at valgt person(er) har signaturrett i selskapet.',
  );
  const enkWarningAppendix = t(
    'enk-warning-appendix',
    'For denne organisasjonen klarer ikke Dun & Bradstreet å tolke signaturteksten. Dette skyldes enten en for kompleks eller egendefinert signaturregel. Dette gjelder i ca 5% av alle organisasjonene i Norge. Siden vi ikke kan garantere at vedkommende har signaturrett må dere selv undersøke hvilke av de som har det, og eventuelt legge til personer som mangler.',
  );

  const signeeOptions = task?.context?.organization?.bisnode?.Foretak?.Person;
  const hasVerifiedSignees =
    task?.context?.organization?.bisnode?.Foretak?.verifiedSignees;

  const isTest = task?.variables?.isTest ?? false;
  const [addCustomSignee, setAddCustomSignee] = useState(false);

  const persons = signeeOptions?.map((person) => {
    return {
      ...person,
      nationalId: person.Fodselsnr,
    };
  });

  const method = useForm({
    resolver: zodResolver(
      z
        .object({
          selectedSignees: z.array(
            z
              .object({
                email: z.string().optional(),
                Navn: z.string().optional(),
                selected: z.boolean().optional(),
                Fodselsnr: z.string().optional(),
                ssn: z.string().optional(),
                nationalId: z.string().optional(),
                Verv: z.any(),
              })

              .superRefine((val, ctx) => {
                const emailRegex =
                  /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

                const checkEmailMatch = val?.email?.match(emailRegex);
                if (val.selected && !checkEmailMatch) {
                  ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: t(
                      'invalid-email-error',
                      'Skriv en gyldig epostadresse',
                    ),
                    path: ['email'],
                  });
                }
                const regex = isTest
                  ? /^(0[1-9]|[1-2][0-9]|31(?!(?:0[2469]|11))|30(?!02))(0[1-9]|1[0-2]|8[1-9]|9[0-2])\d{7}$/
                  : /^(0[1-9]|[1-2][0-9]|31(?!(?:0[2469]|11))|30(?!02))(0[1-9]|1[0-2])\d{7}$/;
                const doesnationalIdmatch = val?.nationalId
                  ?.replaceAll(' ', '')
                  .match(regex);

                if (
                  val.selected &&
                  !doesnationalIdmatch &&
                  val.ssn?.length === 0
                ) {
                  return ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: t(
                      'invalid-ssn-error',
                      'Skriv et gyldig fødselsnummer',
                    ),
                    path: ['nationalId'],
                  });
                }
              }),
          ),
        })
        .superRefine((val, ctx) => {
          const checkIfEmpty = val.selectedSignees.filter(
            (item) => item.selected,
          );
          if (checkIfEmpty.length === 0) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'Vennligst velg en eller flere signatarer',
              path: ['selectedSignees'],
            });
          }
        }),
    ),
    defaultValues: {
      selectedSignees: task?.data?.length > 0 ? task?.data : persons,
    },
  });
  const { append, fields } = useFieldArray({
    control: method.control,
    name: 'selectedSignees',
  });

  const { handleSubmit, watch, formState, resetField } = method;

  function onSubmit(data) {
    const { selectedSignees } = data;
    const filteredPayload = selectedSignees.filter((item) => item.selected);
    const mappedPayload = filteredPayload.map((signee) => {
      return {
        name: signee.Navn,
        email: signee.email,
        nationalId: !!signee?.nationalId
          ? signee?.nationalId?.replaceAll(' ', '')
          : signee?.Fodselsnr,
        role: signee?.Verv ? signee?.Verv?.map((verv) => verv.VervTekst) : [''],
      };
    });
    complete({
      signees: mappedPayload,
      name: task.context.organization.name,
      number: task.context.organization.number,
      rule: { code: '', text: '' },
    });

    return;
  }

  function saveAndClose() {
    const saveData = watch('selectedSignees');
    const mappedSaveData = saveData.map((data) => {
      return {
        ...data,
        email: data?.selected ? data.email : '',
      };
    });
    save(mappedSaveData);
  }

  const isBrregTestData = flowProps?.task?.variables?.useBrregTestData;
  const selectedSignees = watch('selectedSignees');

  if (isBrregTestData) {
    return (
      <div className="w-full h-full flex flex-col gap-4 items-center my-10 justify-start">
        <p>
          {t(
            'test-signees',
            'Det benyttes testsignatarer her, trykk på knappen for å gå videre',
          )}
        </p>
        <Button onClick={() => complete()}>{t('proceed', 'Gå videre')}</Button>
      </div>
    );
  }

  const generateVervCsv = (vervArray) =>
    vervArray?.length > 0
      ? ` - ${vervArray?.map((verv) => verv?.VervTekst).join(', ')}`
      : '';

  const meldinger = task?.context?.organization?.bisnode?.Meldinger;
  const prokuraTekst =
    task?.context?.organization?.bisnode?.Foretak?.ProkuraTekst;
  const signaturTekst =
    task?.context?.organization?.bisnode?.Foretak?.SignaturTekst;

  const didntFindNationalId = t(
    'didnt-find-ssn',
    'Her ser det ut som om D&B ikke fant fødselsnummer på signataren. Dette må legges til manuelt, vennligst kontroller at nummeret du fyller inn er korrekt.',
  );

  return (
    <FormProvider {...method}>
      <form onSubmit={handleSubmit(onSubmit)} className="h-full">
        {!hasVerifiedSignees && (
          <WarningMessage
            message={enkWarning}
            appendixMessage={enkWarningAppendix}
          ></WarningMessage>
        )}
        {meldinger &&
          meldinger.map((melding) => (
            <WarningMessage
              message={`Meldingskode ${melding.MeldingsKode}: ${melding?.MeldingsTekst}`}
            ></WarningMessage>
          ))}

        <div className="grid grid-cols-3 border h-full border-b-0">
          <div className="border-r p-4 col-span-1 flex flex-col gap-4">
            <h2 className="border-b pb-4">
              {t('sign-rules', 'Signeringsregler')}
            </h2>
            <div className="flex flex-col gap-4 border-b pb-4">
              <>
                <data>
                  <dt>Prokura:</dt>
                  <dd>{prokuraTekst}</dd>
                </data>
                <data>
                  <dt>Signatur:</dt>
                  <dd>{signaturTekst}</dd>
                </data>
              </>
            </div>
            <div className="grid gap-4">
              {selectedSignees?.map((person, index) => {
                const watcher = watch(`selectedSignees.[${index}].selected`);
                return (
                  <div>
                    <Checkbox
                      name={`selectedSignees.[${index}].selected`}
                      label={`${person?.Navn}${generateVervCsv(person?.Verv)}`}
                    ></Checkbox>
                    <div className="grid gap-4 pt-4">
                      {watcher && person?.Fodselsnr?.length === 0 ? (
                        <div className="bg-yellow-200 p-2">
                          <p>{didntFindNationalId}</p>
                        </div>
                      ) : null}
                      {watcher && (
                        <TextField
                          name={`selectedSignees.[${index}].email`}
                          label={t('email-label', 'Epost')}
                        />
                      )}
                      {watcher && person?.Fodselsnr?.length === 0 ? (
                        <TextField
                          name={`selectedSignees.[${index}].nationalId`}
                          label={t('ssn-label', 'Fødselsnummer')}
                          maxLength={11}
                        />
                      ) : null}
                    </div>
                  </div>
                );
              })}
              {!!formState?.errors?.selectedSignees && (
                <p className=" text-red-500">
                  {formState?.errors?.selectedSignees?.root?.message as string}
                </p>
              )}
            </div>
            <button
              type="button"
              disabled={!task.permissions.complete}
              className={
                !task.permissions.complete
                  ? 'w-max text-gray-300'
                  : 'w-max text-blue-500 underline cursor-pointer hover:text-blue-400'
              }
              onClick={() => {
                setAddCustomSignee(true);
              }}
            >
              (+) {t('add-custom-signee', 'Legg til egendefinert signatar')}
            </button>
            <div className="flex gap-2 items-center">
              <Button disabled={!task.permissions.complete} primary={true}>
                {t('complete', 'Fullfør')}
              </Button>
              <Button
                onClick={() => saveAndClose()}
                type="button"
                disabled={!task.permissions.save}
                primary={false}
              >
                {t('save-and-close', 'Lagre og lukk')}
              </Button>
            </div>
          </div>
          <div className="p-4 col-span-2">
            {addCustomSignee && (
              <AddCustomSigneeSchema
                watch={watch}
                closeTab={setAddCustomSignee}
                addToList={append}
                reset={resetField}
                isTest={isTest}
                flow={flowProps}
              />
            )}
          </div>
        </div>
      </form>
    </FormProvider>
  );
}
