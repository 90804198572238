import { useFormConnect } from '../../../hooks/useFormConnect';
import { useFlowContext } from '../../../hooks/useFlowContext';

export function Select({
  label,
  id,
  options,
}: {
  label: string;
  id: string;
  options: { value: string; label: string }[];
}) {
  const { name, value, onChange, error } = useFormConnect(id);
  return (
    <div className="flex flex-col gap-2">
      <label className="text-sm" htmlFor={name}>
        {label}
      </label>
      <select
        id={name}
        name={name}
        defaultValue={null}
        value={value}
        onChange={onChange}
        className="selectComponent rounded-md relative focus:outline-none w-full cursor-pointer border border-gray-300 p-2.5 focus:border-transparent focus:ring-2"
      >
        {options.map((option) => (
          <option className="" key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
}
