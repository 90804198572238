import { FormProvider, useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Layout } from '../../../Layout/Layout';
import { Stack } from '../../../Stack/Stack';
import { Button } from '../../../Button/Button';
import {
  AGRICULTURE,
  FACTORING,
  FISHING_TOOLS,
  LEASING,
  OPERATING_EQUIPMENT,
  PledgeType,
  SALES_PLEDGE,
  STORAGE,
  VEHICLE_AND_MACHINERY,
} from '../../fields/PledgeType';
import { MainPledgers } from './MainPledgers';
import { MotorPledge } from './PledgeTypes/MotorPledge';
import { StoragePledge } from './PledgeTypes/StoragePledge';
import { AgriculturePledge } from './PledgeTypes/AgriculturePledge';
import { FishingToolsPledge } from './PledgeTypes/FishingToolsPledge';
import { OperatingEquipmentPledge } from './PledgeTypes/OperatingEquipmentPledge';
import { FactoringPledge } from './PledgeTypes/FactoringPledge';
import { SalesPledge } from './PledgeTypes/SalesPledge';
import { createValidation } from '../../../../../utils/createPledgeValidation';
import { PledgeMapper } from './PledgeTypes/PledgeMapper';
import { IndividualMotorPledge } from './PledgeTypes/IndividualMotorPledge';
import { createPledge } from '../../../../../api/api';
import { useMutation } from '@tanstack/react-query';
import { Leasing } from './PledgeTypes/Leasing';
import { User } from '../../../../../types/user';

export function NewPledgeForm({ flowProps, t }) {
  const creator = flowProps?.context?.creator;
  const isLeasingRole = creator?.role.includes('leasing');
  const [schema, setSchema] = useState(
    z.object({ motor: MotorPledge.validationSchema }),
  );
  const method = useForm({
    resolver: zodResolver(
      z
        .object({
          amount: z.object({
            currency: z.string(),
            value: z.string().optional().transform(Number),
          }),
          type: z.string(),
          fields: schema,
        })
        .superRefine((val, ctx) => {
          const value = val.amount.value;
          if (value <= 0 && val.type !== 'leasing') {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: t(
                'pledge-amount-error',
                'Beløpet må være høyere enn null',
              ),
              path: ['amount.value'],
            });
          }
        }),
    ),
    defaultValues: {
      organization: {},
      amount: {
        currency: 'NOK',
        value: '',
      },
      type: isLeasingRole ? 'leasing': 'operatingEquipment',
      fields: {
        individualMotor: IndividualMotorPledge.defaultValues,
        motor: MotorPledge.defaultValues,
        agriculture: AgriculturePledge.defaultValues,
        storage: StoragePledge.defaultValues,
        salesPledge: SalesPledge.defaultValues,
        fishingTools: FishingToolsPledge.defaultValues,
        operatingEquipment: OperatingEquipmentPledge.defaultValues,
        factoring: FactoringPledge.defaultValues,
        leasing: Leasing.defaultValues,
      },
    },
  });

  const { handleSubmit, formState, watch, getValues, setValue } = method;

  const pledgeTypeMap = {
    individualMotor: VEHICLE_AND_MACHINERY,
    motor: VEHICLE_AND_MACHINERY,
    agriculture: AGRICULTURE,
    storage: STORAGE,
    salesPledge: SALES_PLEDGE,
    fishingTools: FISHING_TOOLS,
    operatingEquipment: OPERATING_EQUIPMENT,
    factoring: FACTORING,
    leasing: LEASING,
  };

  const pledgeType = watch('type');
  useEffect(() => {
    const newSchema = createValidation(pledgeType);
    setSchema(newSchema as any);
  }, [pledgeType]);

  const [isLoading, setIsLoading] = useState(false);
  const { mutate } = useMutation({
    mutationFn: (data) => createPledge(data),
    onMutate: () => {
      setIsLoading(true);
    },
  });

  const onSubmit = (data) => {
    const selectedFields = getValues(`fields.${data.type}` as any);
    const payload = {
      fields: selectedFields,
      amount: data.amount,
      type: pledgeTypeMap[data.type],
    };
    const mappedPayload = PledgeMapper(data.type, payload);

    const mutateData = {
      taskId: flowProps.taskId,
      payload: mappedPayload,
    };

    mutate(mutateData as any);
  };

  return (
    <FormProvider {...method}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Layout split={true}>
          <Stack>
            {pledgeType !== 'leasing' && !isLeasingRole && (
              <MainPledgers watch={watch} />
            )}
            {pledgeType === 'leasing' && isLeasingRole && ( // Keep leasing input in this column for leasing-users
              <Leasing error={formState?.errors} watch={watch} setValue={setValue} />
            )}
            <div className="flex flex-row gap-2 absolute bottom-4 left-4">
              <Button disabled={!flowProps.permissions.complete} type="submit">
                {isLoading ? (
                  <div className=" h-4 w-4 rounded-full animate-spin spinner" />
                ) : (
                  t('complete', 'Fullfør')
                )}
              </Button>
            </div>
          </Stack>
          <Stack>
            {!isLeasingRole && (
              <PledgeType name="type" t={t} user={creator as User} />
            )}
            {pledgeType === 'individualMotor' && (
              <IndividualMotorPledge
                setValue={setValue}
                error={formState?.errors}
                watch={watch}
              />
            )}
            {pledgeType === 'motor' && <MotorPledge watch={watch} />}
            {pledgeType === 'storage' && <StoragePledge watch={watch} />}
            {pledgeType === 'agriculture' && (
              <AgriculturePledge watch={watch} />
            )}
            {pledgeType === 'fishingTools' && (
              <FishingToolsPledge watch={watch} />
            )}
            {pledgeType === 'operatingEquipment' && (
              <OperatingEquipmentPledge watch={watch} />
            )}
            {pledgeType === 'factoring' && <FactoringPledge watch={watch} />}
            {pledgeType === 'salesPledge' && (
              <SalesPledge
                setValue={setValue}
                error={formState?.errors}
                watch={watch}
              />
            )}
            {pledgeType === 'leasing' && !isLeasingRole && (  // Keep leasing input in this column for other users than leasing-users
              <Leasing
                setValue={setValue}
                error={formState?.errors}
                watch={watch}
              />
            )}
          </Stack>
        </Layout>
      </form>
    </FormProvider>
  );
}
