import { useEffect } from 'react';
import { RegisteredVehicles } from '../../forms/fields/RegisteredVehicles';
import { useFlowContext } from '../../../../hooks/useFlowContext';
import clsx from 'clsx';
import { XIcon } from 'lucide-react';

export function RegistrationNumberTextField({
  fieldName,
  watch,
  setValue,
}: {
  fieldName: string;
  watch: (name: string) => string;
  setValue: (a: string, b: string) => void;
}) {
  const { t } = useFlowContext();

  const registeredVehicles = watch(fieldName);

  const formattedRegNumbers = registeredVehicles?.split(/[,.\n\s]/);

  function removeTextStringFromTotalString(text, totalString) {
    setValue(fieldName, totalString.replace(text, ''));
  }

  function findMultipleInstancesOfText(text, totalString) {
    const instances = totalString
      .split(/[,.\n\s]/)
      .filter((instance) => instance.toLowerCase() === text.toLowerCase());
    return (
      instances.length > 1 && text.toLowerCase() === instances[0].toLowerCase()
    );
  }

  function replaceAllBreaksWithNewLine() {
    const newString = registeredVehicles.replace(/[,.\s]/g, '\n');
    let newerString = newString.replace(/\n\n/g, '\n');
    if (newerString.startsWith('\n') || newerString.startsWith('\n\r')) {
      newerString = newerString.slice(1);
    }
    setValue(fieldName, newerString);
  }

  useEffect(() => {
    replaceAllBreaksWithNewLine();
  }, [registeredVehicles]);

  return (
    <div className="flex flex-col gap-4">
      <div>
        <RegisteredVehicles
          label={t(
            'write-or-paste-registered-vehicles',
            'Skriv eller lim inn registreringsnummer på motorvogner som skal pantsettes:',
          )}
          name={fieldName}
        />
      </div>
      <div className="flex gap-4 flex-wrap">
        {formattedRegNumbers?.map((vehicle) => {
          const hasOther = findMultipleInstancesOfText(
            vehicle,
            registeredVehicles,
          );

          const regNrRegex = /^[A-Za-z]{2}[1-9]\d{3,4}$/;
          const isMatching = vehicle.match(regNrRegex);
          const hasError = !isMatching || hasOther;

          if (vehicle === '' || vehicle === '\n' || vehicle === '\n\r')
            return null;
          return (
            <div>
              <button
                type="button"
                onClick={() =>
                  removeTextStringFromTotalString(vehicle, registeredVehicles)
                }
                className={clsx(
                  'p-2 bg-blue-200 flex flex-row gap-2 items-center',
                  hasOther || !isMatching ? 'border-b border-red-500' : '',
                )}
              >
                {vehicle}
                <XIcon size={12} />
              </button>
              {hasError && (
                <span className="text-red-500">
                  {!isMatching
                    ? t('error-registration-number', 'Feil RegNr')
                    : t('error-duplicate-enty', 'Duplikat')}
                </span>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}
