import { z } from 'zod';
import { AgriculturePledge } from '../components/_shared/forms/schemas/NewPledge/PledgeTypes/AgriculturePledge';
import { MotorPledge } from '../components/_shared/forms/schemas/NewPledge/PledgeTypes/MotorPledge';
import { StoragePledge } from '../components/_shared/forms/schemas/NewPledge/PledgeTypes/StoragePledge';
import { SalesPledge } from '../components/_shared/forms/schemas/NewPledge/PledgeTypes/SalesPledge';
import { FactoringPledge } from '../components/_shared/forms/schemas/NewPledge/PledgeTypes/FactoringPledge';
import { FishingToolsPledge } from '../components/_shared/forms/schemas/NewPledge/PledgeTypes/FishingToolsPledge';
import { OperatingEquipmentPledge } from '../components/_shared/forms/schemas/NewPledge/PledgeTypes/OperatingEquipmentPledge';
import { IndividualMotorPledge } from '../components/_shared/forms/schemas/NewPledge/PledgeTypes/IndividualMotorPledge';
import { Leasing } from '../components/_shared/forms/schemas/NewPledge/PledgeTypes/Leasing';

export function createValidation(type) {
  switch (type) {
    case 'motor':
      return z.object({ motor: MotorPledge.validationSchema });
    case 'individualMotor':
      return z.object({
        individualMotor: IndividualMotorPledge.validationSchema,
      });
    case 'agriculture':
      return z.object({
        agriculture: AgriculturePledge.validationSchema,
      });
    case 'factoring': {
      return z.object({
        factoring: FactoringPledge.validationSchema,
      });
    }
    case 'fishingTools': {
      return z.object({
        fishingTools: FishingToolsPledge.validationSchema,
      });
    }
    case 'operatingEquipment': {
      return z.object({
        operatingEquipment: OperatingEquipmentPledge.validationSchema,
      });
    }
    case 'salesPledge':
      return z.object({ salesPledge: SalesPledge.validationSchema });
    case 'storage':
      return z.object({ storage: StoragePledge.validationSchema });
    case 'leasing':
      return z.object({ leasing: Leasing.validationSchema });
  }
}
