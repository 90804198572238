import { FlowContext } from '../../../hooks/useFlowContext';
import { TaskProps } from '../../../types/TaskProps';
import { StartTinglysingSchema } from './StartTinglysingSchema';

export function StartTinglysing(props: TaskProps) {
  return (
    <FlowContext.Provider value={props}>
      <StartTinglysingSchema flowProps={props} />
    </FlowContext.Provider>
  );
}
