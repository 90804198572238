export function PledgeMapper(type: string, data: any) {
  let selected = data.fields.selected;
  switch (type) {
    case 'motor':
      return {
        amount: data.amount,
        type: 'NYKA',
        fields: {
          registeredVehicles:
            selected === 'sikkerhet_1_3_checkbox'
              ? data.fields.registeredVehicles
              : [],
          unregisteredVehicles:
            selected === 'sikkerhet_1_3_checkbox'
              ? data.fields.unregisteredVehicles
              : [],
          sikkerhet_1_1_checkbox: selected === 'sikkerhet_1_1_checkbox',
          sikkerhet_1_2_checkbox: selected === 'sikkerhet_1_2_checkbox',
          sikkerhet_1_3_checkbox: selected === 'sikkerhet_1_3_checkbox',
          sikkerhet_1_2_fritekst:
            selected === 'sikkerhet_1_2_checkbox'
              ? data?.fields?.sikkerhet_1_2_fritekst
              : '',
        },
      };
    case 'individualMotor':
      return {
        amount: data.amount,
        type: 'NYKA',
        fields: {
          registeredVehicles: data.fields.registeredVehicles
            ?.split(/[,.\n\s]/)
            ?.filter((instance) => instance.length > 0),
          unregisteredVehicles: data.fields.unregisteredVehicles,
          sikkerhet_1_1_checkbox: false,
          sikkerhet_1_2_checkbox: false,
          sikkerhet_1_3_checkbox: true,
          sikkerhet_1_2_fritekst: '',
        },
      };
    case 'storage':
      return {
        amount: data.amount,
        type: 'NYVL',
        fields: {
          sikkerhet_1_1_checkbox: selected === 'sikkerhet_1_1_checkbox',
          sikkerhet_1_2_checkbox: selected === 'sikkerhet_1_2_checkbox',
          sikkerhet_1_2_fritekst:
            selected === 'sikkerhet_1_2_checkbox'
              ? data?.fields?.sikkerhet_1_2_fritekst
              : '',
        },
      };

    case 'agriculture':
      return {
        amount: data.amount,
        type: 'NYLP',
        fields: {
          sikkerhet_1_1_checkbox: selected === 'sikkerhet_1_1_checkbox',
          sikkerhet_1_2_checkbox: selected === 'sikkerhet_1_2_checkbox',
          sikkerhet_1_2_fritekst:
            selected === 'sikkerhet_1_2_checkbox'
              ? data?.fields?.sikkerhet_1_2_fritekst
              : '',
        },
      };

    case 'fishingTools':
      return {
        amount: data.amount,
        type: 'NYFP',
        fields: {
          sikkerhet_1_1_checkbox: selected === 'sikkerhet_1_1_checkbox',
          sikkerhet_1_2_checkbox: selected === 'sikkerhet_1_2_checkbox',
          sikkerhet_1_2_fritekst:
            selected === 'sikkerhet_1_2_checkbox'
              ? data?.fields?.sikkerhet_1_2_fritekst
              : '',
        },
      };
    case 'operatingEquipment':
      return {
        amount: data.amount,
        type: 'NYDT',
        fields: {
          sikkerhet_1_1_checkbox: selected === 'sikkerhet_1_1_checkbox',
          sikkerhet_1_2_checkbox: selected === 'sikkerhet_1_2_checkbox',
          sikkerhet_1_2_fritekst:
            selected === 'sikkerhet_1_2_checkbox'
              ? data?.fields?.sikkerhet_1_2_fritekst
              : '',
        },
      };
    case 'salesPledge':
      return {
        amount: data.amount,
        type: 'NYSP',
        fields: {
          selger_checkbox: selected === 'selger_checkbox',
          tredjemann_checkbox: selected === 'tredjemann_checkbox',
          registeredVehicles: data.fields.registeredVehicles
            ?.split(/[,.\n\s]/)
            ?.filter((instance) => instance.length > 0),
        },
      };

    case 'factoring':
      return {
        amount: data.amount,
        type: 'NYFA',
        fields: {
          avtale_1_1_checkbox:
            data?.fields?.selectedOne === 'avtale_1_1_checkbox',
          avtale_1_2_checkbox:
            data?.fields?.selectedOne === 'avtale_1_2_checkbox',
          avtale_1_3_checkbox:
            data?.fields?.selectedOne === 'avtale_1_3_checkbox',
          avtale_2_1_checkbox:
            data?.fields?.selectedTwo === 'avtale_2_1_checkbox',
          avtale_2_2_checkbox:
            data?.fields?.selectedTwo === 'avtale_2_2_checkbox',
          avtale_2_3_checkbox:
            data?.fields?.selectedTwo === 'avtale_2_3_checkbox',
          avtale_3_1_checkbox:
            data?.fields?.selectedThree === 'avtale_3_1_checkbox',
          avtale_3_2_checkbox:
            data?.fields?.selectedThree === 'avtale_3_2_checkbox',
          avtale_3_3_fritekst:
            data?.fields?.selectedThree === 'avtale_3_2_checkbox'
              ? data?.fields?.avtale_3_3_fritekst
              : '',
        },
      };

    case 'leasing':
      return {
        type: 'NYLA',
        fields: {
          registeredVehicles: data.fields.registeredVehicles
            ?.split(/[,.\n\s]/)
            ?.filter((instance) => instance.length > 0),
        },
      };
  }
}
