import { Combobox as HeadlessUiCombobox, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';
import { ChevronsUpDown, Check } from 'lucide-react';
import { ErrorMessage } from '../../ErrorMessage/ErrorMessage';

type Options = {
  id: string | number;
  label: string;
  disabled?: boolean;
  value?: string;
  buttonLabel?: string;
};

type ComboboxProps<T> = {
  name?: string;
  placeholder?: string;
  disabled?: boolean;
  buttonClassName?: string;
  optionsListClassName?: string;
  variant?: 'default' | 'onOffWhite';
  label?: string;
  value: T;
  onChange: (value: T) => void;
  error?: string;
  options?: Options[];
};

export function Combobox({
  buttonClassName,
  optionsListClassName,
  options,
  variant,
  disabled,
  name,
  placeholder,
  value,
  onChange,
  error,
  label,
}: ComboboxProps<Options>) {
  const [query, setQuery] = useState('');
  const errorId = `${name}-error`;
  const filteredOptions =
    query === ''
      ? options
      : options.filter((option) => {
          return option.label
            .toString()
            .toLowerCase()
            .replace(/\s+/g, '')
            .includes(query.toLowerCase().replace(/\s+/g, ''));
        });

  if (!options) return null;

  return (
    <div>
      <HeadlessUiCombobox value={value} onChange={onChange} name={name}>
        {label && (
          <HeadlessUiCombobox.Label className="text-burgundy">
            {label}
          </HeadlessUiCombobox.Label>
        )}
        <div className="relative">
          <HeadlessUiCombobox.Input
            className="w-full p-3 border-gray-300 border"
            displayValue={(option: Options) =>
              option?.buttonLabel ?? option?.label
            }
            onChange={(event) => setQuery(event.target.value)}
            placeholder={placeholder}
          />
          <HeadlessUiCombobox.Button className="absolute inset-y-0 flex justify-end items-center pr-2 w-full">
            <ChevronsUpDown className="h-[16px] w-[16px]" />
          </HeadlessUiCombobox.Button>

          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 -translate-y-2"
            afterLeave={() => setQuery('')}
          >
            <HeadlessUiCombobox.Options className="ring-burgundy absolute z-20 mt-1 max-h-60 w-full overflow-auto bg-white text-base ring-1 ring-opacity-5 focus:outline-none">
              {filteredOptions.length === 0 && query !== '' ? (
                <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                  Nothing found.
                </div>
              ) : (
                filteredOptions.map((option) => (
                  <HeadlessUiCombobox.Option
                    key={option.id}
                    value={option}
                    disabled={option.disabled}
                    className={
                      'data-[headlessui-state=active]:bg-blue-100 focus:bg-blue-100 relative cursor-pointer hover:bg-blue-100 select-none py-2 px-2 pr-4 data-[headlessui-state=active]:bg-opacity-10'
                    }
                  >
                    {({ active, selected }) => (
                      <span className="flex items-center gap-2">
                        {selected ? (
                          <Check className="h-[15px] w-[15px]" />
                        ) : null}
                        <span className={selected ? '' : 'pl-6'}>
                          {' '}
                          {option.label}
                        </span>
                      </span>
                    )}
                  </HeadlessUiCombobox.Option>
                ))
              )}
            </HeadlessUiCombobox.Options>
          </Transition>
        </div>
      </HeadlessUiCombobox>

      {error && (
        <div className="mt-2">
          <ErrorMessage message={error} id={errorId} />
        </div>
      )}
    </div>
  );
}
