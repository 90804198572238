import { useQuery } from '@tanstack/react-query';
import { FormProvider, useForm } from 'react-hook-form';
import { RadioButtonList } from '../../../RadioButtonList/RadioButtonList';
import { TextField } from '../../fields/TextField';
import { Button } from '../../../Button/Button';
import { zodResolver } from '@hookform/resolvers/zod';
import z from 'zod';
import { getAttachments } from '../../../../../api/api';
import { LoadingSpinner } from '../../../Spinner/Spinner';

export function ApproveDocumentSchema({ props }) {
  const method = useForm({
    resolver: zodResolver(
      z
        .object({
          approveDocument: z.string().min(1, 'Vennligst ta en avgjørelse'),
          internalNotes: z.string().optional(),
        })
        .superRefine((val, ctx) => {
          if (
            val.approveDocument === 'reject' &&
            val.internalNotes.length === 0
          ) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'Vennligst legg til kommentar',
              path: ['internalNotes'],
            });
          }
        }),
    ),
    defaultValues: {
      approveDocument: '',
      internalNotes: '',
    },
  });

  const access_token = props.user.access_token;
  const currentPledgeIndex = props.task.variables?.currentPledgeIndex;
  const flowId = props.flow.flowId;
  const attachmentKey =
    props.flow.data.pledges?.[currentPledgeIndex]?.document?.filledDocument
      ?.attachmentKey;

  const { data, isFetching, isError, error, refetch } = useQuery({
    queryKey: [attachmentKey],
    queryFn: () => getAttachments(access_token, flowId, attachmentKey),
  });

  const { complete, close } = props;

  const { handleSubmit } = method;

  function onSubmit(data) {
    const formatPayload = {
      approveDocument: data.approveDocument === 'approve' ? true : false,
      internalNotes: data.internalNotes,
    };

    complete(formatPayload);
  }

  const { t } = props;

  const options = [
    {
      label: t('approve', 'Godkjenn'),
      value: 'approve',
    },
    {
      label: t('reject', 'Avslå'),
      value: 'reject',
    },
  ];

  if (isFetching) {
    return (
      <div className="flex items-center justify-center h-full flex-col">
        <LoadingSpinner />
        <p>{t('loading-document', 'Laster inn dokument')}...</p>
      </div>
    );
  }

  if (isError) {
    return (
      <div className="flex items-center justify-start mt-8 gap-8 flex-col">
        <p>
          {t(
            'error-occured',
            'Det oppstod en feil, vennligst lukk vinduet eller prøv igjen.',
          )}
        </p>
        <div className="flex gap-4">
          <Button onClick={() => refetch()}>
            {t('try-again', 'Prøv igjen')}
          </Button>
          <Button primary={false} type="button" onClick={() => close()}>
            {t('close', 'Lukk')}
          </Button>
        </div>
      </div>
    );
  }

  return (
    <FormProvider {...method}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-3 gap-4">
          <iframe
            className=" min-h-screen w-full col-span-2"
            title="pdf"
            src={data}
          />
          <div className=" px-2 py-4 flex flex-col gap-6">
            <p className="font-bold">{t('decision', 'Beslutning')}</p>
            <RadioButtonList id="approveDocument" options={options} />
            <TextField
              name="internalNotes"
              label={t('internal-notes', 'Internkommentar')}
            />
            <div>
              <Button disabled={!props.task.permissions.complete} type="submit">
                {t('complete', 'Fullfør')}
              </Button>
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  );
}
