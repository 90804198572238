import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Button } from '../../../Button/Button';
import { Mail } from '../../fields/Mail';
import { Checkbox } from '../../fields/Checkbox';
import { useState } from 'react';

export function AddMockedOrganizationsSchema({ flowProps }) {
  const { complete } = flowProps;
  const brregOrgs = [
    {
      name: '810332662',
      label: 'NORDFJORDEID OG TYINKRYSSET REVISJON ANS FIKTIVE TESTDATA',
      value: false,
    },
    {
      name: '810305932',
      label: 'IDSE OG VESTBY REVISJON STI FIKTIVE TESTDATA',
      value: false,
    },
    {
      name: '810306092',
      label: 'LINDÅS OG STAMNES REVISJON AS FIKTIVE TESTDATA',
      value: false,
    },
    {
      name: '810306912',
      label: 'MOSTERØY OG SKEDSMOKORSET REVISJON AS FIKTIVE TESTDATA',
      value: false,
    },
    {
      name: '810307242',
      label: 'HUSTAD OG SKALLESTAD REVISJON IKS FIKTIVE TESTDATA',
      value: false,
    },
    {
      name: '810310022',
      label: 'RYKKINN OG SYLTE REVISJON AS FIKTIVE TESTDATA',
      value: false,
    },
    {
      name: '810311312',
      label: 'HEMNES OG KORGEN REVISJON AS FIKTIVE TESTDATA',
      value: false,
    },
    {
      name: '810312262',
      label: 'NESLANDSVATN OG TONSTAD REVISJON AS FIKTIVE TESTDATA',
      value: false,
    },
    {
      name: '810312742',
      label: 'DRIVA OG KJELDEBOTN REVISJON AS FIKTIVE TESTDATA',
      value: false,
    },
    {
      name: '910433296',
      label: 'TRANBY OG RØDBERG REGNSKAP Fiktive testdata',
      value: false,
    },
    {
      name: '810059672',
      label: 'AASEN & FARSTAD AS',
      value: false,
    },
    {
      name: '910313231',
      label: '(automatic) LR-Måsøy og Mjosundet Revisjon (ENK)',
      value: false,
    },
    {
      name: '810305282',
      label: '(automatic) DRAMMEN OG KVAMSØY REVISJON AS FIKTIVE TESTDATA',
      value: false,
    },
    {
      name: '810310782',
      label: '(automatic) VERDAL OG SURNADAL REVISJON AS FIKTIVE TESTDATA',
      value: false,
    },
    {
      name: '810311142',
      label: '(automatic) SVELGEN OG BRANDASUND REVISJON AS FIKTIVE TESTDATA',
      value: false,
    },
    {
      name: '810304642',
      label: '(automatic) VANNAREID OG HONNINGSVÅG REVISJ AS FIKTIVE TESTDATA',
      value: false,
    },
    {
      name: '810305002',
      label:
        '(automatic) MOEN OG HALDEN REVISJON AS FIKTIVE TESTDATA (contains signee blocked from signing)',
      value: false,
    },
  ];
  const method = useForm({
    resolver: zodResolver(
      z.object({
        brregOrgs: z
          .array(
            z.object({
              name: z.string().optional(),
              label: z.string().optional(),
              value: z.boolean().optional(),
            }),
          )
          .optional(),
        testSigningEmail: z.union([z.literal(''), z.string().email()]),
      }),
    ),
    defaultValues: {
      brregOrgs: brregOrgs,
      testSigningEmail: '',
    },
  });

  const { handleSubmit, control, formState } = method;

  const [error, setError] = useState(false);
  const onSubmit = (data) => {
    const selected = data.brregOrgs.filter((org) => org.value === true);
    const payload = {
      brregOrgs: selected?.map((org) => org.name),
      testSigningEmail: data.testSigningEmail,
    };
    complete(payload);
  };

  const { t } = flowProps;

  return (
    <FormProvider {...method}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-3 border">
          <div className="border-r p-4 col-span-1 h-screen">
            <div className="grid gap-4">
              <h2>
                {t(
                  'choose-mocked-organization-help-text',
                  ' Velg en eller flere av disse organisasjonene for å teste tinglysing (automatic betyr at brreg håndterer tinglysing automatisk, de andre må håndteres manuelt. Nina må kontaktes):',
                )}
              </h2>
              {brregOrgs.map((checkbox, index) => (
                <Checkbox
                  name={`brregOrgs[${index}].value`}
                  label={checkbox.label}
                />
              ))}
              <Button
                disabled={!flowProps.task.permissions.complete}
                primary={true}
              >
                {t('save', 'Lagre')}
              </Button>
            </div>
          </div>
          <div className="p-4">
            <h2>
              {t(
                'test-signing-email-help-text',
                'Signerings-epost (Overskriver signatur regler, alle dokumenter som skal signeres blir sendt til denne eposten)',
              )}
            </h2>
            <Mail name="testSigningEmail" />
          </div>
        </div>
      </form>
    </FormProvider>
  );
}
