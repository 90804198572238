import { Button } from '../../../components/_shared/Button/Button';
import { Trash2, Undo } from 'lucide-react';
import { currencyMask } from '../../../components/_shared/Inputs/masks';
import { useState } from 'react';

const groupBy = (data, key) => {
  return Object.values(
    data.reduce((acc, obj) => {
      const objKey = JSON.stringify(obj[key]);
      if (!acc[objKey]) {
        acc[objKey] = { key: obj[key], values: [] };
      }
      acc[objKey].values.push(obj);
      return acc;
    }, {}),
  ).map(({ values }) => values);
};

export function StartTinglysingSchema({ flowProps }) {
  const { complete, flow, save, t } = flowProps;
  const [deletedPledges, setDeletedPledges] = useState(
    flowProps?.task?.data?.documentsMarkedForDeletion || [],
  );
  const documents = groupBy(
    flow.data?.pledges?.filter((pledge) => pledge.document?.approveDocument),
    'organization',
  );

  const markAsDeleted = (pledgeId: string) => {
    setDeletedPledges([...deletedPledges, pledgeId]);
  };

  const unmarkAsDeleted = (pledgeId: string) => {
    setDeletedPledges(
      deletedPledges.filter((deletedPledge) => deletedPledge !== pledgeId),
    );
  };

  return (
    <div className="p-10 flex flex-col items-start">
      {documents.length > 0 && documents[0].length > 0 ? (
        documents.map((documentCollection, index) => {
          return (
            <div className="p-4 mb-4 border w-auto" key={index}>
              <div className="p-4 w-96">
                {documentCollection[0].organization.number} -{' '}
                {documentCollection[0].organization.name}
              </div>
              <table className="table-fixed text-sm text-left rtl:text-right">
                <tbody>
                  {documentCollection.map((pledge) => {
                    const amount = pledge.document?.inputRaw?.amount?.value;
                    const currency =
                      pledge.document?.inputRaw?.amount?.currency;
                    return (
                      <tr
                        className={
                          deletedPledges.includes(pledge.pledgeId) &&
                          'text-decoration-line: line-through'
                        }
                        key={pledge.pledgeId}
                      >
                        <td className="px-4 py-2 w-72">
                          {pledge.document.outputFilename}
                        </td>
                        <td className="px-4 py-2 w-48">
                          {amount > 0 && currencyMask.formatter(amount)}{' '}
                          {currency}
                        </td>
                        {deletedPledges.includes(pledge.pledgeId) ? (
                          <td className="px-4 py-2 text-green-500">
                            <button
                              onClick={() => unmarkAsDeleted(pledge.pledgeId)}
                            >
                              <Undo />
                            </button>
                          </td>
                        ) : (
                          <td className="px-4 py-2 text-red-500">
                            <button
                              onClick={() => markAsDeleted(pledge.pledgeId)}
                            >
                              <Trash2 />
                            </button>
                          </td>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          );
        })
      ) : (
        <div>No documents</div>
      )}
      <div className="flex flex-row items-center gap-4">
        <Button
          onClick={() =>
            complete({ documentsMarkedForDeletion: deletedPledges })
          }
        >
          Start
        </Button>
        <Button
          primary={false}
          onClick={() => save({ documentsMarkedForDeletion: deletedPledges })}
        >
          {t('save', 'Save')}
        </Button>
      </div>
    </div>
  );
}
