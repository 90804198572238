import React, { useState, useEffect } from 'react';
import { calculateTimeDifference } from '../../../utils/parseDuration';

interface CountdownTimerProps {
  expiryTimestamp: Date;
  translate?: (key: string) => string;
  warningThreshold?: number;
  errorThreshold?: number;
  showDays?: boolean;
  showHours?: boolean;
  showMinutes?: boolean;
  showSeconds?: boolean;
}

interface TimeLeft {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

const CountdownTimer: React.FC<CountdownTimerProps> = ({
  expiryTimestamp,
  translate,
  warningThreshold,
  errorThreshold = 3,
  showDays = true,
  showHours = false,
  showMinutes = false,
  showSeconds = false,
}) => {
  const calculateTimeLeft = (): TimeLeft | {} => {
    const difference = +new Date(expiryTimestamp) - +new Date();
    let timeLeft: TimeLeft | {} = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState<TimeLeft | {}>(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });
  const thresholdColors = (
    endDate: Date,
    errorThreshold: number,
    warningThreshold?: number,
  ) => {
    const daysLeft = calculateTimeDifference(new Date(), endDate).days;
    if (daysLeft <= errorThreshold) {
      return 'text-dangerDefault';
    }
    if (warningThreshold && daysLeft <= warningThreshold) {
      return 'text-strokeWarningLight';
    }
    return '';
  };
  return (
    <div>
      {'days' in timeLeft ? (
        <span
          className={
            '' +
            thresholdColors(expiryTimestamp, errorThreshold, warningThreshold)
          }
        >
          {showDays &&
            `${timeLeft.days} ${translate ? translate('days') : 'days'}`}
          {showHours &&
            `${timeLeft.hours} ${translate ? translate('hours') : 'hours'}`}
          {showMinutes &&
            `${timeLeft.minutes} ${
              translate ? translate('minutes') : 'minutes'
            }`}
          {showSeconds &&
            `${timeLeft.seconds} ${
              translate ? translate('seconds') : 'seconds'
            }`}
        </span>
      ) : (
        <span>{translate ? translate('Expired') : 'Expired'}</span>
      )}
    </div>
  );
};

export default CountdownTimer;
