import { FlowContext } from '../../../hooks/useFlowContext';
import { TaskProps } from '../../../types/TaskProps';
import { CompleteAllDocumentControlSchema } from './CompleteAllDocumentControlSchema';

export function CompleteAllDocumentControl(props: TaskProps) {
  return (
    <FlowContext.Provider value={props}>
      <CompleteAllDocumentControlSchema flowProps={props} />
    </FlowContext.Provider>
  );
}
