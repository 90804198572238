import { useFormConnect } from '../../../../hooks/useFormConnect';
import { InputBox } from '../../Inputs/InputBox';

type CustomAmountProps = {
  name: string;
  label: string;
  min?: number;
  max?: number;
};
export const CustomAmount = ({ name, label, min, max }: CustomAmountProps) => {
  const props = useFormConnect(name);
  return (
    <InputBox type="string" maxLength={2} label={label} {...props}></InputBox>
  );
};
