import { Check, Clock } from 'lucide-react';
import { withTranslation } from 'react-i18next';

const RegistrationStatus = ({ pledge, t }) => {
  const status = pledge.registration?.status?.status?.toLowerCase();
  const manuallyHandled = pledge.registration?.status?.manuallyHandled;
  return (
    <>
      <div className="flex flex-row gap-2">
        {(status === 'tinglyst' || manuallyHandled) && (
          <div className="text-green-500" title={t('document-is-registered','Dokumentet er tinglyst')}>
            <Check className="h-5 w-5" />
          </div>
        )}
        {(status === 'nektet tinglyst' || status === 'rejected') && !manuallyHandled  && (
          <div className="text-yellow-500" title={t('manual-registration','Manuell tinglysing')}>
            <Clock className="h-5 w-5" />
          </div>
        )}
        {!pledge.registration?.status?.status && !manuallyHandled  && (
          <div className="text-yellow-500" title={t('waiting-for-registration','Venter på tinglysing')}>
            <Clock className="h-5 w-5" />
          </div>
        )}
        <div className="">
          {pledge.document?.outputFilename?.split('.')?.[0]}
        </div>
        {(status === 'nektet tinglyst' || status === 'rejected') && !manuallyHandled && (
          <div
            className="text-gray-600 opacity-80 italic"
            title={t('manual-registration','Manuell tinglysing')}
          >
            {t('manual-registration','Manuell tinglysing')}
          </div>
        )}
      </div>
    </>
  );
};

export default withTranslation()(RegistrationStatus);
