import { Button } from '../../../components/_shared/Button/Button';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { GetDurationInDays, GetEndDate } from '../../../utils/parseDuration';
import { triggerMessageTaskWithBody } from '../../../api/api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Select } from '../../../components/_shared/Select/Select';

export function ExtendSigningDeadlineSchema({
  t,
  flowProps,
  setOpen,
  allMessageTasks,
  currentTasks,
  signee,
}) {
  const { variables, context, flowId } = flowProps;
  const getDaysBetweenTwoDates = (date1: Date, date2: Date) => {
    const diffTime = Math.abs(date2.getTime() - date1.getTime());
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  };
  const daysUntilDeadline = Number(
    GetDurationInDays(variables?.signingStartTime, variables?.deadline),
  );
  const signingExpiresAt = new Date(
    Date.parse(
      context?.pledges?.find((pledge) => pledge.signing?.signingExpiresAt)
        .signing?.signingExpiresAt,
    ),
  );
  const daysUntilFinalDeadline = getDaysBetweenTwoDates(
    new Date(),
    signingExpiresAt,
  );

  let options = [];
  for (let i = 10; i < 40; i = i + 10) {
    if (daysUntilDeadline + i <= daysUntilFinalDeadline) {
      options.push({
        label: `${i} ${t('days', 'dager')}`,
        value: i,
      });
    }
  }
  const canExtendDeadline = options?.length > 0;
  const defaultOption = `${options?.[options?.length - 1 || 0]?.value}`;

  const method = useForm({
    resolver: zodResolver(
      z.object({
        deadline: z.string(),
        newDeadline: z.string().optional(),
      }),
    ),
    defaultValues: {
      deadline: variables?.deadline || '',
      newDeadline: defaultOption,
    },
  });

  const { handleSubmit, watch } = method;

  const queryClient = useQueryClient();
  const handleSuccess = () => {
    setOpen(false);
    queryClient.invalidateQueries({
      queryKey: ['getExtendedPledgeTasks', flowId],
    });
  };
  const { mutate, isLoading, isError } = useMutation({
    mutationFn: async (data: {
      id: string;
      payload: {
        newDeadline: string;
        newSigningStartTime: string;
      };
    }) => await triggerMessageTaskWithBody(data.id, data.payload),
    onSuccess: () => handleSuccess(),
  });

  const watchDeadline = watch('newDeadline');
  const date = GetEndDate(variables?.signingStartTime, variables?.deadline);
  const formatCurrentDeadline = `${date.getDate()}/${
    date.getMonth() + 1
  }/${date.getFullYear()}`;

  function getDateFromDate(date, newDate) {
    return new Date(date.getTime() + newDate * 24 * 60 * 60 * 1000);
  }

  const dateFromDate = getDateFromDate(date, watchDeadline);
  const newDeadlineString = `${dateFromDate.getDate()}/${
    dateFromDate.getMonth() + 1
  }/${dateFromDate.getFullYear()}`;

  const allMessageTasksForSignee = currentTasks.map((task) => {
    const foundTask = allMessageTasks.find(
      (messageTask) =>
        messageTask.variables.signingTask.url === task.signeeStatus.signUrl,
    );
    return {
      deadline: foundTask?.variables?.deadline,
      signingStartTime: foundTask?.variables?.signingStartTime,
      taskId: foundTask?.taskId,
      nationalId: task.signeeStatus.nationalId,
      signUrl: task.signeeStatus.signUrl,
    };
  });

  const onSubmit = (data) => {
    const now = new Date();
    let first = true;
    allMessageTasksForSignee.forEach((task) => {
      const daysUntilDeadline = Number(
        GetDurationInDays(task?.signingStartTime, task?.deadline),
      );
      if (!task.deadline) return;
      const formatNewDeadline = `P${
        daysUntilDeadline + Number.parseInt(data.newDeadline)
      }D`;

      const payload = {
        newDeadline: formatNewDeadline,
        newSigningStartTime: now.toISOString(),
        sendEmail: first, // Only send one email for all tasks
      };

      first = false;
      mutate({ id: task.taskId, payload: payload });
    });
  };

  return (
    <FormProvider {...method}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="p-8 flex flex-col justify-between gap-4">
          <div className="border-containerBorder p-4 bg-containerBackground rounded border flex flex-col gap-4">
            <p className="font-semibold">
              {t('set-new-deadline', 'Sett ny frist')}
            </p>
            {canExtendDeadline ? (
              <>
                <div className="flex flex-col">
                  <dl className="flex gap-2">
                    <dt>{t('signee', 'Signatar')}:</dt>
                    <dd>{signee?.name}</dd>
                  </dl>
                  <dl className="flex gap-2">
                    <dt>
                      {t('original-deadline', 'Opprinnelig signeringsfrist')}:
                    </dt>
                    <dd>{formatCurrentDeadline}</dd>
                  </dl>
                </div>
                <div className="w-1/2">
                  <Select
                    id="newDeadline"
                    label={t('select-number-of-days', 'Velg antall dager')}
                    options={options}
                  />
                </div>
                <dl className="flex gap-2">
                  <dt>{t('new-deadline', 'Ny signeringsfrist')}:</dt>
                  <dd>{newDeadlineString}</dd>
                </dl>
              </>
            ) : (
              <>
                {`${t(
                  'maximum-allowed-signing-duration-exceeded',
                  'Denne signeringen kan ikke utsettes mer.',
                )}`}
              </>
            )}
          </div>

          {canExtendDeadline && (
            <Button type="submit" primary={true}>
              {isLoading
                ? t('loading', 'Laster...')
                : t('extend-deadline', 'Utsett frist')}
            </Button>
          )}
          {isError && (
            <span className="text-red-500">
              {t('something-went-wrong', 'Obs! Noe gikk galt! Prøv igjen.')}
            </span>
          )}
        </div>
      </form>
    </FormProvider>
  );
}
