import { FlowContext } from '../../../hooks/useFlowContext';
import { TaskProps } from '../../../types/TaskProps';
import { ApproveDocumentSchema } from '../../../components/_shared/forms/schemas/ApproveDocumentSchema/ApproveDocumentSchema';
import { ArchiveModal } from '../../../components/_shared/ArchiveModal/ArchiveModal';

export function Archive(props: TaskProps) {
  return (
    <FlowContext.Provider value={props}>
      <ArchiveModal props={props} />
    </FlowContext.Provider>
  );
}
