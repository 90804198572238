import { z } from 'zod';
import { RegistrationNumberTextField } from '../../../../Inputs/RegistrationNumberTextField/RegistrationNumberTextField';
import { useFlowContext } from '../../../../../../hooks/useFlowContext';

export function Leasing({ error, watch, setValue }) {
  const { t } = useFlowContext();

  return (
    <div className="flex flex-col gap-4 w-full">
      <RegistrationNumberTextField
        watch={watch}
        setValue={setValue}
        fieldName="fields.leasing.registeredVehicles"
      />
      {error?.fields?.leasing?.root && (
        <p className=" text-red-500">
          {t(error?.fields?.leasing?.root?.message)}
        </p>
      )}
    </div>
  );
}

Leasing.validationSchema = z
  .object({
    registeredVehicles: z.string().optional(),
  })
  .superRefine((val, ctx) => {
    const { registeredVehicles } = val;

    if (registeredVehicles.length === 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'atleast-one-vehicle-error',
        path: ['root'],
      });
    }

    if (
      registeredVehicles.length > 0 &&
      registeredVehicles.split(/[,.\n\s]/).length > 50
    ) {
      return ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'no-more-than-fifty-error',
        path: ['registeredVehicles'],
      });
    }

    const splitRegVehicles = registeredVehicles.split(/[,.\n\s]/);
    if (splitRegVehicles[splitRegVehicles.length - 1] === '') {
      splitRegVehicles.pop();
    }
    const hasDuplicates = splitRegVehicles.some(
      (vehicle, index, array) => array.indexOf(vehicle) !== index,
    );
    if (hasDuplicates) {
      return ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'duplicate-vehicles-error',
        path: ['registeredVehicles'],
      });
    }

    const hasInvalidRegNr = splitRegVehicles.some(
      (vehicle) => !vehicle.match(/^[A-Za-z]{2}[1-9]\d{3,4}$/),
    );
    if (hasInvalidRegNr) {
      return ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'invalid-reg-nr',
        path: ['registeredVehicles'],
      });
    }
  });

Leasing.defaultValues = {
  registeredVehicles: '',
};
