import { AlertCircleIcon } from 'lucide-react';

export function NotificationsItem({ notification, hasRead, t, lang }) {
  const updatedAt = getTimeDifference(notification.updatedAt, t, lang);
  const defaultIncidentKey =
    Object.values(notification?.incidents || [])?.[0]?.['message'] ??
    notification?.data?.queue ??
    'error-unknown';

  const translateIncidentKey = t[defaultIncidentKey]?.[lang] ?? 'Ukjent feil';

  return (
    <a
      href={`/inbox/tinglysing/case/${notification?.flowId}`}
      className={` ${
        hasRead ? 'bg-white' : 'bg-blue-50'
      } flex font-thin items-center justify-between gap-32 hover:bg-blue-100 p-4 border-b border-blue-200`}
    >
      <div className="flex gap-3 items-center text-red-500">
        <AlertCircleIcon size={18} />
        <div className="flex flex-col gap-2 justify-around items-start text-gray-800">
          <p className=" font-semibold">
            {notification?.data?.companyName}{' '}
            <span className="font-thin">{translateIncidentKey}</span>
          </p>
          <p className="italic">
            {notification?.assignee?.name ??
              t['no-assignee'][lang] ??
              'Ingen saksbehandler'}
          </p>
        </div>
      </div>
      <p> {updatedAt}</p>
    </a>
  );
}

export function getTimeDifference(timestamp, t, lang) {
  const currentTime = new Date();
  const targetTime = new Date(timestamp);

  const differenceInMilliseconds = Number(currentTime) - Number(targetTime);
  const millisecondsInMinute = 1000 * 60;
  const millisecondsInHour = millisecondsInMinute * 60;

  const minutesSince = Math.floor(
    differenceInMilliseconds / millisecondsInMinute,
  );
  const hoursSince = Math.floor(differenceInMilliseconds / millisecondsInHour);
  const daysSince = Math.floor(
    differenceInMilliseconds / (millisecondsInHour * 24),
  );

  if (minutesSince < 60) {
    return minutesSince + ' ' + t['minutes-ago'][lang]; // TODO: i18n
  } else if (hoursSince < 24) {
    return hoursSince + ' ' + t['hours-ago'][lang];
  } else {
    return daysSince + ' ' + t['days-ago'][lang];
  }
}
