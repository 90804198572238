import { useFieldArray } from 'react-hook-form';
import { TextField } from '../../../fields/TextField';
import { useState } from 'react';
import { z } from 'zod';
import { useFlowContext } from '../../../../../../hooks/useFlowContext';
import { RegistrationNumberTextField } from '../../../../Inputs/RegistrationNumberTextField/RegistrationNumberTextField';

export function IndividualMotorPledge({ watch, error, setValue }) {
  const [isUnregisteredOpen, setIsUnregisteredOpen] = useState(0);

  const { t } = useFlowContext();

  const {
    fields: unregisteredVehicles,
    append: addUnregisteredVehicle,
    remove: removeUnregistedVehicle,
  } = useFieldArray({
    name: 'fields.individualMotor.unregisteredVehicles',
  });

  function addUngregVehicle() {
    addUnregisteredVehicle({
      make: '',
      id: '',
      year: '',
    });
    setIsUnregisteredOpen(unregisteredVehicles.length);
  }

  const unregVehicles = watch('fields.individualMotor.unregisteredVehicles');

  return (
    <div className=" grid gap-4 max-w-full">
      <p className="border-b">
        Til sikkerhet for betaling av kapitalen med renter og omkostninger m.m.
        pantsettes:
      </p>
      <div className="flex flex-col gap-4 w-full">
        <RegistrationNumberTextField
          watch={watch}
          setValue={setValue}
          fieldName="fields.individualMotor.registeredVehicles"
        />
        <p>{t('unregistered-vehicle', 'Uregistrert kjøretøy')}:</p>
        <div className="grid gap-4">
          {unregisteredVehicles.map((vehicle, index) => {
            if (isUnregisteredOpen === index) {
              return (
                <div className="p-2 bg-blue-100  grid gap-2">
                  <div className="flex flex-row justify-between">
                    <p className="border-b border-gray-300">
                      {t('unregistered-vehicle', 'Uregistrert kjøretøy')}{' '}
                      {index + 1}
                    </p>
                    {index + 1 === unregisteredVehicles.length && (
                      <button
                        type="button"
                        onClick={() => removeUnregistedVehicle(index)}
                        className="hover:underline border-dotted border-b border-red-500 text-red-500"
                      >
                        {t('delete', 'Slett')}
                      </button>
                    )}
                  </div>
                  <TextField
                    name={`fields.individualMotor.unregisteredVehicles.[${index}].make`}
                    label={`Fabrikktype/merke`}
                    maxLength={40}
                  />
                  <TextField
                    name={`fields.individualMotor.unregisteredVehicles.[${index}].id`}
                    label={`Identifikasjonsnr/Understellsnr`}
                    maxLength={40}
                  />
                  <TextField
                    name={`fields.individualMotor.unregisteredVehicles.[${index}].year`}
                    label={`Årsmodell`}
                    maxLength={4}
                  />
                </div>
              );
            } else {
              const isEmptyField =
                unregVehicles?.[index]?.make === '' ||
                unregVehicles?.[index]?.id === '' ||
                unregVehicles?.[index]?.year === '';
              return (
                <div className="flex flex-row justify-between bg-blue-50 p-2">
                  {isEmptyField ? (
                    <p className=" text-red-500">
                      {t('unfinished-warning', 'NB! Denne er uferdig')}
                    </p>
                  ) : (
                    <p>
                      {t('unregistered-vehicle', 'Uregistert kjøretøy')}{' '}
                      {index + 1}
                    </p>
                  )}

                  <button
                    className="underline"
                    onClick={() => setIsUnregisteredOpen(index)}
                  >
                    {t('edit', 'Rediger')}
                  </button>
                </div>
              );
            }
          })}
          <button
            type="button"
            onClick={() => addUngregVehicle()}
            className="hover:underline w-max text-blue-500"
          >
            +{' '}
            {t('add-ungregistered-vehicles', 'Legg til uregistrerte kjøretøy')}
          </button>
        </div>
        {error?.fields?.individualMotor?.noVehiclesSelected && (
          <p className="text-red-500">
            {t(
              error?.fields?.individualMotor?.noVehiclesSelected.message,
              'Legg til minst ett kjøretøy',
            )}
          </p>
        )}
      </div>
    </div>
  );
}

IndividualMotorPledge.validationSchema = z
  .object({
    registeredVehicles: z.string().optional(),
    unregisteredVehicles: z
      .array(
        z.object({
          make: z.string().min(1, 'add-brand'),
          id: z.string().min(1, 'add-id-number'),
          year: z.string().regex(/^[12][0-9]{3}$/, 'add-valid-model'),
        }),
      )
      .max(45, 'no-more-than-fourt-five'),
  })
  .superRefine((val, ctx) => {
    const { registeredVehicles, unregisteredVehicles } = val;

    if (registeredVehicles.length === 0 && unregisteredVehicles.length === 0) {
      return ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'add-vehicles-error',
        path: ['noVehiclesSelected'],
      });
    }
    if (
      registeredVehicles.length > 0 &&
      registeredVehicles.split(/[,.\n\s]/).length > 50
    ) {
      return ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'no-more-than-fifty-error',
        path: ['registeredVehicles'],
      });
    }

    const splitRegVehicles = registeredVehicles.split(/[,.\n\s]/);
    if (splitRegVehicles[splitRegVehicles.length - 1] === '') {
      splitRegVehicles.pop();
    }
    const hasDuplicates = splitRegVehicles.some(
      (vehicle, index, array) => array.indexOf(vehicle) !== index,
    );
    if (hasDuplicates) {
      return ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'duplicate-vehicles-error',
        path: ['registeredVehicles'],
      });
    }

    const hasInvalidRegNr = splitRegVehicles.some(
      (vehicle) => !vehicle.match(/^[A-Za-z]{2}[1-9]\d{3,4}$/),
    );
    if (hasInvalidRegNr) {
      return ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'invalid-reg-nr',
        path: ['registeredVehicles'],
      });
    }
  });

IndividualMotorPledge.defaultValues = {
  registeredVehicles: '',
  unregisteredVehicles: [],
};
