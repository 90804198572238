import { forwardRef } from 'react';
import clsx from 'clsx';
import { ConnectedProps } from '../../../../types/ConnectedProps';
import { useFlowContext } from '../../../../hooks/useFlowContext';

export type TextAreaProps<T> = {
  name: string;
  placeholder?: string;
  autoComplete?: string;
  disabled?: boolean;
  inputClassNames?: string;
} & ConnectedProps<T>;

export const TextArea = forwardRef<HTMLInputElement, TextAreaProps<string>>(
  ({
    onChange,
    value,
    name,
    label,
    placeholder,
    error,
    disabled,
    inputClassNames,
  }) => {
    const errorId = `${name}-error`;

    const { t } = useFlowContext();
    const textareaStyles = clsx(
      'block h-32 w-full border border-gray-300 rounded-md outline-none ring-0 p-2 resize-none',
      inputClassNames,
      disabled ? 'bg-gray-300' : '',
      error ? 'focus:outline-none !bg-error' : '',
    );

    return (
      <div>
        <label htmlFor={name} className="block">
          {label}
        </label>
        <div className="relative mt-1">
          <textarea
            name={name}
            id={name}
            placeholder={placeholder}
            autoComplete="off"
            value={value}
            disabled={disabled}
            onChange={(e) => onChange(e.target.value)}
            className={textareaStyles}
          />
        </div>
        {error && (
          <p className="pt-2 text-red-500" id={errorId}>
            {t(error)}
          </p>
        )}
      </div>
    );
  },
);
