import { useState } from 'react';
import { Minus, Plus, Timer } from 'lucide-react';
import Step from './Step';
import { PhaseMapper } from '../../utils/phaseMapper';
import ExpandCollapseButton from './ExpandCollapseButton';
import { useTranslation, withTranslation } from 'react-i18next';
import { GetEndDate } from '../../utils/parseDuration';
import CountdownTimer from '../../components/_shared/CountdownTimer/CountdownTimer';

function CaseSummary(props) {
  const minimumOrganizationsToShow = 5;
  const numberOfOrganizations = props.caseData?.data?.organizations?.length;

  const { i18n } = useTranslation();
  const { t } = i18n;

  const [expand, setExpand] = useState(minimumOrganizationsToShow);

  const flowSteps = PhaseMapper(
    props.caseData?.data?.organizations,
    props.caseData,
  );

  const expandTable = () => {
    setExpand(expand + 5);
  };

  const isSigning = props?.caseData?.data?.queue === 'SIGNING_DOCUMENTS';
  const timestampStartedSigning =
    props?.caseData?.data?.timestampStartedSigning;
  const endDate = GetEndDate(timestampStartedSigning ?? '', 'P90D');
  const prettyEndDate = endDate.toLocaleDateString(
    i18n.language === 'no' ? 'no-NO' : 'en-EN',
    {
      dateStyle: 'short',
    },
  );
  return (
    <div className="grid-rows-2 border rounded-md border-strokePrimary relative overflow-hidden">
      <div className="w-full text-left">
        <div>
          <div className="bg-surfaceSecondary p-4 border-b border-strokePrimary flex items-center justify-between ">
            <span>{t('summary')}</span>
            {isSigning && timestampStartedSigning && (
              <div
                title={`${t('deadline')}: ${prettyEndDate}`}
                className={'pl-4 flex gap-1 text-sm items-center'}
              >
                <Timer className="h-4 w-4" />
                <span>{`${t('signing-expires')}:`}</span>
                <CountdownTimer
                  expiryTimestamp={endDate}
                  translate={t}
                ></CountdownTimer>
              </div>
            )}
          </div>
          <div className="text-contentSecondary grid grid-cols-3 px-12 py-4">
            <div className="text-left col-auto text-sm font-normal">
              {t('organization')}
            </div>
            <div className=" col-span-2 grid grid-cols-4 ">
              <div className="text-left text-sm col-span-1 font-normal">
                {t('signees')}
              </div>
              <div className="text-center text-sm col-span-1 font-normal">
                {' '}
                {t('documents')}
              </div>
              <div className="text-center text-sm col-span-1 font-normal">
                {' '}
                {t('signing')}
              </div>
              <div className="text-right text-sm col-span-1 font-normal">
                {t('tinglysing')}
              </div>
            </div>
          </div>
        </div>

        <div>
          {flowSteps
            ?.slice(0, expand)
            .map((flowStep, index) => (
              <Step flowProps={props} flowStep={flowStep} key={index} />
            ))}
        </div>
      </div>
      {numberOfOrganizations > 5 && (
        <div className="p-4 flex justify-center border-t">
          {expand < numberOfOrganizations && (
            <ExpandCollapseButton onClick={expandTable} color="#467EFF">
              <Plus />
            </ExpandCollapseButton>
          )}
          {expand >= numberOfOrganizations && numberOfOrganizations > 5 && (
            <ExpandCollapseButton
              onClick={() => setExpand(minimumOrganizationsToShow)}
              color="#467EFF"
            >
              <Minus />
            </ExpandCollapseButton>
          )}
        </div>
      )}
    </div>
  );
}

export default withTranslation()(CaseSummary);
