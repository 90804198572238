import { useQuery } from '@tanstack/react-query';
import { Button } from '../Button/Button';
import { getAttachments } from '../../../api/api';
import { LoadingSpinner } from '../Spinner/Spinner';

export function ArchiveModal({ props }) {
  const access_token = props.user.access_token;
  const flowId = props.flow.flowId;
  const { data, isFetching, isError, error, refetch } = useQuery({
    queryKey: [flowId],
    queryFn: () => getAttachments(access_token, flowId, 'caseSummary'),
  });

  const { complete, close, t } = props;

  if (isFetching) {
    return (
      <div className="flex items-center justify-center h-full flex-col">
        <LoadingSpinner />
        <p>{t('loading-document', 'Laster inn dokument')}...</p>
      </div>
    );
  }

  if (isError) {
    return (
      <div className="flex items-center justify-start mt-8 gap-8 flex-col">
        <p>
          {t(
            'error-occured',
            'Det oppstod en feil, vennligst lukk vinduet eller prøv igjen.',
          )}
        </p>
        <div className="flex gap-4">
          <Button onClick={() => refetch()}>
            {t('try-again', 'Prøv igjen')}
          </Button>
          <Button primary={false} type="button" onClick={() => close()}>
            {t('close', 'Lukk')}
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="grid grid-cols-4">
      <iframe
        className=" min-h-screen w-full col-span-3"
        title="pdf"
        src={data}
      />
      <div className="p-4 flex flex-col gap-2">
        <p>
          {t(
            'case-tinglyst',
            'Saken er tinglyst. Trykk på knappen under for å avslutte',
          )}
        </p>
        <Button onClick={() => complete()}>
          {t('complete-case', 'Fullfør sak')}
        </Button>
      </div>
    </div>
  );
}
