import { FlowContext } from '../../../hooks/useFlowContext';
import { TaskProps } from '../../../types/TaskProps';
import { ConfigureSigneesSchema } from '../../../components/_shared/forms/schemas/ConfigureSigneesSchema/ConfigureSigneesSchema';

export function ConfigureSignees(props: TaskProps) {
  return (
    <FlowContext.Provider value={props}>
      <ConfigureSigneesSchema flowProps={props} />
    </FlowContext.Provider>
  );
}
