import { z } from 'zod';
import { RadioButtonList } from '../../../../RadioButtonList/RadioButtonList';
import { useFlowContext } from '../../../../../../hooks/useFlowContext';
import { RegistrationNumberTextField } from '../../../../Inputs/RegistrationNumberTextField/RegistrationNumberTextField';

export function SalesPledge({ error, watch, setValue }) {
  const options = [
    {
      key: 'selger_checkbox',
      defaultValue: false,
      label:
        'Selgerens krav pá kjøpesummen med tillegg av rente og omkostninger.',
      value: 'selger_checkbox',
    },
    {
      key: 'tredjemann_checkbox',
      defaultValue: true,
      label:
        'Lån som tredjeperson har gitt kjøperen til hel eller delvis betaling av kjøpesummen med tillegg av rente og omkostninger, og som långiveren utbetaler direkte til selgeren.',
      value: 'tredjemann_checkbox',
    },
  ];

  const { t } = useFlowContext();

  return (
    <>
      <RadioButtonList id="fields.salesPledge.selected" options={options} />
      <RegistrationNumberTextField
        watch={watch}
        setValue={setValue}
        fieldName="fields.salesPledge.registeredVehicles"
      />
      {error?.fields?.salesPledge?.root && (
        <p className=" text-red-500">
          {t(error?.fields?.salesPledge?.root?.message)}
        </p>
      )}
    </>
  );
}

SalesPledge.validationSchema = z
  .object({
    selected: z.string(),
    registeredVehicles: z.string().optional(),
  })
  .superRefine((val, ctx) => {
    const { registeredVehicles } = val;
    if (registeredVehicles.length === 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'add-vehicle',
        path: ['selected'],
      });
    }
    if (
      registeredVehicles.length > 0 &&
      registeredVehicles.split(/[,.\n\s]/).length > 50
    ) {
      return ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'no-more-than-fifty-error',
        path: ['registeredVehicles'],
      });
    }

    const splitRegVehicles = registeredVehicles.split(/[,.\n\s]/);
    if (splitRegVehicles[splitRegVehicles.length - 1] === '') {
      splitRegVehicles.pop();
    }

    const hasDuplicates = splitRegVehicles.some(
      (vehicle, index, array) => array.indexOf(vehicle) !== index,
    );
    if (hasDuplicates) {
      return ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'duplicate-vehicles-error',
        path: ['registeredVehicles'],
      });
    }

    const hasInvalidRegNr = splitRegVehicles.some(
      (vehicle) => !vehicle.match(/^[A-Za-z]{2}[1-9]\d{3,4}$/),
    );
    if (hasInvalidRegNr) {
      return ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'invalid-reg-nr',
        path: ['registeredVehicles'],
      });
    }
  });

SalesPledge.defaultValues = {
  selected: 'tredjemann_checkbox',
  registeredVehicles: '',
};
