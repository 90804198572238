import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Checkbox } from '../../../components/_shared/forms/fields/Checkbox';
import { Button } from '../../../components/_shared/Button/Button';

export function RemoveOrganizationsSchema({ flowProps }) {
  const { trigger, flow, save, task, t } = flowProps;
  const method = useForm({
    resolver: zodResolver(
      z
        .object({
          organizations: z
            .array(
              z.object({
                id: z.number().optional(),
                number: z.string().optional(),
                name: z.string().optional(),
                value: z.boolean().optional(),
              }),
            )
            .nonempty(),
        })
        .superRefine((val, ctx) => {
          const checkedAmount = val.organizations.filter(
            (org) => org.value === true,
          ).length;
          if (checkedAmount === 0) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'remove-organization-error',
              path: ['organizations'],
            });
          }
        }),
    ),
    defaultValues: {
      organizations:
        task.data && task.data.length > 0
          ? task.data
          : flow.data?.organizations?.map((organization) => ({
              id: organization.id,
              name: organization.name,
              number: organization.number,
            })),
    },
  });

  const { handleSubmit, watch, formState } = method;

  const onSubmit = (data) => {
    trigger({
      organizations: data.organizations
        .filter((org) => org.value === true)
        .map((org) => org.id),
    });
  };

  function saveAndClose() {
    const saveData = watch('organizations');
    save(saveData);
  }

  const organizations = watch('organizations');

  return (
    <FormProvider {...method}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-4 p-10">
          <div>
            <h1>
              {t(
                'remove-organizations-help-text',
                'Velg organisasjonene du vil fjerne',
              )}
            </h1>
            <div className="italic">
              {t(
                'remove-organizations-help-disclaimer',
                'Vær obs på at pantedokumenter som er knyttet til organisasjonene også vil bli fjernet',
              )}
            </div>
          </div>
          <div className="grid gap-4">
            {organizations &&
              organizations?.map((organization, index) => {
                return (
                  <Checkbox
                    name={`organizations[${index}].value`}
                    label={`${organization.number} - ${organization.name}`}
                  />
                );
              })}
          </div>

          {(formState?.errors?.organizations as any) && (
            <p className="text-red-500">
              {t(formState?.errors?.organizations.message)}
            </p>
          )}
          <div className="flex gap-4 pt-4 ">
            <Button
              disabled={!flowProps.task.permissions.trigger}
              primary={true}
            >
              {t('delete')}
            </Button>
            <Button
              onClick={() => saveAndClose()}
              type="button"
              disabled={!task.permissions.save}
              primary={false}
            >
              {t('save-and-close', 'Lagre og lukk')}
            </Button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
}
