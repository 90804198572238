export function HouseIcon({ color = 'blue' }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none">
      <path
        fill="#467EFF"
        d="m8.565.235 7.2 7.2a.799.799 0 0 1-.566 1.365L14 8.665v7.2H2v-7.2L.8 8.8a.8.8 0 0 1-.566-1.366l7.2-7.2a.8.8 0 0 1 1.131 0Z"
      />
    </svg>
  );
}
