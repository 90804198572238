import { parse, toSeconds } from 'iso8601-duration';

export const calculateTimeDifference = (date1: Date, date2: Date) => {
  const diffMilliseconds = Math.abs(date2?.getTime() - date1?.getTime());
  const days = Math.floor(diffMilliseconds / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (diffMilliseconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
  );
  const minutes = Math.floor(
    (diffMilliseconds % (1000 * 60 * 60)) / (1000 * 60),
  );
  const seconds = Math.floor((diffMilliseconds % (1000 * 60)) / 1000);
  return { days, hours, minutes, seconds };
};

export function ParseISODuration(duration: string) {
  if (!duration) return -1;
  return toSeconds(parse(duration)) * 1000;
}

export function GetDurationInDays(start: string, duration: string) {
  const startDate = new Date(start);
  if (duration) {
    const durationInMilliseconds = ParseISODuration(duration);
    const endDate = new Date(startDate.getTime() + durationInMilliseconds);
    const timeLeft = calculateTimeDifference(new Date(), endDate);
    return timeLeft.days + 1; // Add one day to include the current day
  } else {
    return '';
  }
}

export function GetEndDate(start: string, duration: string) {
  const startDate = new Date(start);
  const durationInMilliseconds = ParseISODuration(duration);
  return new Date(startDate.getTime() + durationInMilliseconds);
}

export function GetDateFromNow(newDate: number) {
  return new Date(new Date().getTime() + newDate * 24 * 60 * 60 * 1000);
}
