import { FlowContext } from '../../../hooks/useFlowContext';
import { NewPledgeForm } from '../../../components/_shared/forms/schemas/NewPledge/NewPledge';
import { TaskProps } from '../../../types/TaskProps';
export function AddPledge(props) {
  const { t } = props;
  return (
    <FlowContext.Provider value={props}>
      <NewPledgeForm flowProps={props} t={props.t} />
    </FlowContext.Provider>
  );
}
