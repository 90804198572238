import { useFlowContext } from '../../../../../hooks/useFlowContext';
import { Amount } from '../../fields/Amount';
import { Currency } from '../../fields/Currency';

export function MainPledgers({ watch }) {
  const watchCurrency = watch('amount.currency');
  const props = useFlowContext();
  const { t } = props;
  return (
    <>
      <Currency
        label={t('currency', 'Valuta')}
        name="amount.currency"
      ></Currency>
      <Amount
        label={t('amount-currency-label', `Beløp ({{currency}})`, {
          currency: watchCurrency ? watchCurrency : 'NOK',
        })}
        name="amount.value"
      ></Amount>
    </>
  );
}
