import './config.dev';
import { CaseManager, APPS, FEATURES } from '@flow/case-manager';
import {
  customComponents as components,
  caseAssignmentConfiguration,
} from './customer';
import no from './translations/no.json';
import dk from './translations/dk.json';
import en from './translations/en.json';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import './globals.css';
import { Header } from './customer/branding/Header';
import { withTranslation } from 'react-i18next';
import { FlowContext } from './hooks/useFlowContext';

function headerWithUser(user) {
  const skin = {
    mainLogo: () => {
      return <Header user={user} />;
    },
  };
  return skin;
}

const language = localStorage.getItem('language') || 'no';

function App({ t }) {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
      },
    },
  });
  return (
    <QueryClientProvider client={queryClient}>
      <FlowContext.Provider value={{ t }}>
        <CaseManager
          configuration={(user) => {
            return {
              homeUrl: '/inbox',
              apps: [APPS.Inbox, APPS.Search, APPS.Process],
              skin: headerWithUser(user),
              inboxConfiguration: {
                features: [FEATURES.CaseAssignment],
                queuesPollingEnabled: false,
                activityTimeout: 60 * 60 * 1000,
                startableProcesses: ['case'],
                searchFields: ['referenceId'],
              },
              caseAssignmentConfiguration,
              customComponents: components(user),
              translates: [
                {
                  language: 'no',
                  translates: no,
                },
                {
                  language: 'en',
                  translates: en,
                },
                {
                  language: 'dk',
                  translates: dk,
                },
              ],
              language: language,
              searchConfiguration: {
                searchProcesses: ['case'],
                searchQueryView: 'noData',
                searchResults: {
                  businessStatus: {
                    map: (flow) => flow.data.queue,
                  },
                },
                search: {
                  fields: [
                    {
                      name: t('companyName', 'Selskapsnavn'),
                      value: 'data.companyName',
                      queryOperator: 'contains',
                    },
                    {
                      name: t('organization', 'Organisasjon'),
                      value: 'data.organizations.name',
                      queryOperator: 'contains',
                    },
                    {
                      name: t('Organisasjonsnummer', 'Organisasjonsnummer'),
                      value: 'data.organizations.number',
                      queryOperator: 'contains',
                    },
                  ],
                },
              },
            };
          }}
        />
        <ReactQueryDevtools initialIsOpen={false} />
      </FlowContext.Provider>
    </QueryClientProvider>
  );
}

export default withTranslation()(App);
