import { FlowContext } from '../../../hooks/useFlowContext';
import { TaskProps } from '../../../types/TaskProps';
import { AddMockedOrganizationsSchema } from '../../../components/_shared/forms/schemas/AddMockedOrganizationsSchema/AddMockedOrganizationsSchema';
export function AddMockedOrganizations(props: TaskProps) {
  return (
    <FlowContext.Provider value={props}>
      <AddMockedOrganizationsSchema flowProps={props} />
    </FlowContext.Provider>
  );
}
