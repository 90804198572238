import { HouseIcon } from '../../../components/icons/HouseIcon';
import { queueNames } from './queueNames';
import { ProcessingIcon } from '../../../components/icons/ProcessingIcon';

export const menuStructure = [
  {
    name: 'tinglysing',
    elems: [
      queueNames.MY_CASES,
      queueNames.CASES,
      queueNames.FAILED_CASES,
      queueNames.EXPIRED_CASES,
      queueNames.TERMINATED_CASES,
      queueNames.COMPLETED_CASES,
    ],
    isDefaultOpen: true,
    icon: HouseIcon,
  },
  {
    name: 'in-progress-queue',
    elems: [
      queueNames.AWAITING_CONFIGURE_SIGNEES,
      queueNames.AWAITING_ADD_PLEDGE_INFORMATION,
      queueNames.AWAITING_DOCUMENT_CONTROL,
      queueNames.AWAITING_SIGNING_DOCUMENTS,
      queueNames.AWAITING_DOCUMENT_CONTROL_REGISTRATION,
      queueNames.AWAITING_REGISTRATION,
      queueNames.AWAITING_DOWNLOAD_FILES_FOR_ARCHIVING,
    ],
    isDefaultOpen: true,
    icon: ProcessingIcon,
  },
];
