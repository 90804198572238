/**
 * This is the shared error context component, the component displays errors.
 * @param {Object} props
 * @param {string} props.id
 * @param {Object} props.error
 * @param {string} props.error.message
 */

import { useFlowContext } from '../../../hooks/useFlowContext';

type ErrorMessageProps = {
  id: string;
  message: string;
};

export function ErrorMessage({ id, message }: ErrorMessageProps) {
  const { props } = useFlowContext();
  const { t } = props;
  if (!message || message === '') {
    return null;
  }

  return (
    <div id={id} className="bg-red text-offWhite p-4">
      {t(message)}
    </div>
  );
}
