import { useState } from 'react';
import { Button } from '../../../Button/Button';
import { TextField } from '../../fields/TextField';
import { useFlowContext } from '../../../../../hooks/useFlowContext';

type Props = {
  flow: any;
  addToList: (any) => void;
  closeTab: (any) => void;
  watch: (any) => string;
  reset: (any) => void;
  isTest: boolean;
};

export default function AddCustomSigneeSchema({
  flow,
  addToList,
  watch,
  closeTab,
  reset,
  isTest,
}: Props) {
  const [ssnError, setSsnError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);

  const props = useFlowContext();
  const { t } = props;
  function addCustomSignee() {
    const regex = isTest
      ? /^(0[1-9]|[1-2][0-9]|31(?!(?:0[2469]|11))|30(?!02))(0[1-9]|1[0-2]|8[1-9]|9[0-2])\d{7}$/
      : /^(0[1-9]|[1-2][0-9]|31(?!(?:0[2469]|11))|30(?!02))(0[1-9]|1[0-2])\d{7}$/;
    const ssn = watch('ssn');
    const name = watch('name');
    const email = watch('email');

    const doesSSNmatch = String(ssn).replaceAll(' ', '').match(regex);

    if (name?.length === 0 || name === undefined) {
      setNameError(true);
      return;
    }
    setNameError(false);
    if (!!doesSSNmatch === false) {
      setSsnError(true);
      return;
    }
    setSsnError(false);
    if (
      email === undefined ||
      email?.length === 0 ||
      email.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/) === null
    ) {
      setEmailError(true);
      return;
    }
    setEmailError(false);
    addToList({
      Navn: watch('name'),
      Fodselsnr: watch('ssn'),
      ssn: watch('ssn'),
      email: watch('email'),
      selected: true,
      FullmaktTekst: ['Egendefinert signatar'],
    });
    reset('name');
    reset('ssn');
    reset('email');
    closeTab(false);
    return;
  }

  return (
    <div className="flex flex-col gap-2">
      <h3 className=" text-md">
        {t('custom-signee', 'Egendefinert signatar')}
      </h3>
      <TextField name="name" label={t('name-label', 'Navn')} />
      {nameError && (
        <p className="text-red-500">
          {t('add-name-error', 'Vennligst legg til et gyldig navn')}
        </p>
      )}
      <TextField
        name="ssn"
        label={t('ssn-label', 'Fødselsnummer')}
        maxLength={11}
      />
      {ssnError && (
        <p className="text-red-500">
          {t('invalid-ssn-error', 'Vennligst legg til et gyldig fødselsnummer')}
        </p>
      )}
      <TextField name="email" label={t('email-label', 'Epost')} />
      {emailError && (
        <p className="text-red-500">
          {t('invalid-email-error', 'Vennligst legg til en gyldig epost')}
        </p>
      )}
      <div className="flex w-max gap-2">
        <Button primary type="button" onClick={() => addCustomSignee()}>
          {t('add', 'Legg til')}
        </Button>
      </div>
    </div>
  );
}
