import { Button } from '../../../components/_shared/Button/Button';
import { complete, getPendingMessageTasks } from '../../../api/api';

export function CompleteAllDocumentControlSchema({ flowProps }) {
  const { trigger, t, flow, close } = flowProps;

  async function completeAll() {
    const tasks = await getPendingMessageTasks(flow.flowId, 'document-control');
    if (!tasks.length) {
      close();
      return;
    }
    await Promise.all(
      tasks.map((task) => {
        const formatPayload = {
          approveDocument: true,
          internalNotes: 'completed by complete-all-document-control',
        };
        return complete(task.taskId, formatPayload);
      }),
    );
    trigger({ formData: null });
    return;
  }

  return (
    <div className="flex items-center justify-start flex-col py-8 gap-8">
      <p>{t('accept-all-documents')}?</p>
      <div className=" flex gap-4 items-center">
        <Button onClick={() => completeAll()}>{t('yes').toUpperCase()}</Button>
      </div>
    </div>
  );
}
