//NB! This must correspond to the one that exists in the flow app -> queueHelper.ts
export const queueNames = {
  FAILED_CASES: 'failed-cases-queue',
  TERMINATED_CASES: 'terminated-cases-queue',
  EXPIRED_CASES: 'expired-cases-queue',
  COMPLETED_CASES: 'completed-cases-queue',
  TINGLYSING: 'tinglysing',
  AWAITING_CONFIGURE_SIGNEES: 'awaiting-configure-signees-queue',
  AWAITING_ADD_PLEDGE_INFORMATION: 'awaiting-add-pledge-information-queue',
  AWAITING_DOCUMENT_CONTROL: 'awaiting-document-control-queue',
  AWAITING_DOCUMENT_CONTROL_REGISTRATION:
    'awaiting-document-control-registration-queue',
  AWAITING_SIGNING_DOCUMENTS: 'awaiting-signing-documents-queue',
  AWAITING_REGISTRATION: 'awaiting-registration-queue',
  AWAITING_DOWNLOAD_FILES_FOR_ARCHIVING:
    'awaiting-download-files-for-archiving-queue',
  CASES: 'tinglysing',
  MY_CASES: 'my-cases',
  ADD_PLEDGE_INFORMATION: 'add-pledge-information',
  SIGNING_DOCUMENTS: 'signing-documents',
  CONFIGURE_SIGNEES: 'configure-signees',
  DOCUMENT_CONTROL_REGISTRATION: 'document-control-registration',
  REGISTRATION: 'registration',
  DOWNLOAD_FILES_FOR_ARCHIVING: 'download-files-for-archiving',
};
