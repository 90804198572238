import { useRef } from 'react';
import { NotificationsItem } from './NotificationsItem';
import { useClickOutside } from '../../../hooks/useClickOutside';
import { FadeIn } from '../../../components/transitions/FadeIn';
import { t } from './notificationTranslations';
export function NotificationsList({
  onClose,
  notifications,
  unreadNotifications,
  readNotifications,
  setRead,
}) {
  const wrapper = useRef(null);
  useClickOutside(wrapper, onClose);

  const selectedLang = localStorage.getItem('language') || 'no';

  return (
    <FadeIn>
      <div
        ref={wrapper}
        className="flex flex-col bg-white p-4 shadow-md min-w-max"
      >
        <div className="flex justify-between px-2 pb-3 items-start">
          <span className=" text-lg font-medium">
            {t['notificaions-title'][selectedLang] ?? 'Varsler'}
          </span>
        </div>
        <div className="max-h-80 overflow-y-auto">
          {unreadNotifications.length > 0 &&
            unreadNotifications.map((notification) => {
              return (
                <NotificationsItem
                  t={t}
                  lang={selectedLang}
                  notification={notification}
                  hasRead={false}
                />
              );
            })}
          {readNotifications.length > 0 &&
            readNotifications.map((notification) => {
              return (
                <NotificationsItem
                  t={t}
                  lang={selectedLang}
                  notification={notification}
                  hasRead={true}
                />
              );
            })}
          {notifications.length === 0 && (
            <div className="flex font-thin items-center justify-between w-full gap-32 bg-blue-50 hover:bg-blue-100 p-4 border-b border-blue-200">
              <div className="flex items-center justify-center">
                <span className="font-thin">
                  {t['no-failed-cases-message'][selectedLang] ??
                    'Ingen feilede saker'}
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    </FadeIn>
  );
}
