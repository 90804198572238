import React from 'react';

const ExpandCollapseButton = ({ onClick, color, children }) => {
  return (
    <button
      onClick={onClick}
      className="w-8 h-8 rounded-full bg-blue-50 border-gray-200 border flex items-center justify-center"
    >
      {React.cloneElement(children, { color: color })}
    </button>
  );
};

export default ExpandCollapseButton;
