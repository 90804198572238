import { useFlowContext } from '../../../hooks/useFlowContext';
import { useFormConnect } from '../../../hooks/useFormConnect';

type RadioButtonListProps = {
  id: string;
  options: {
    label: string;
    value: any;
  }[];
};

export function RadioButtonList({ id, options }: RadioButtonListProps) {
  const { value, onChange, name, error } = useFormConnect(id);
  const { t } = useFlowContext();

  return (
    <div>
      <fieldset>
        <legend className="sr-only">Decision radio buttons</legend>
        <div className="space-y-3">
          {options.map((option) => (
            <div key={option.value} className="flex items-center">
              <input
                value={option.value}
                id={option.label}
                name={name}
                type="radio"
                checked={option.value === value}
                className="h-4 min-w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                onChange={(e) => onChange(e.target.value)}
              />
              <label
                htmlFor={option.label}
                className="ml-5 block text-sm font-medium text-gray-700"
              >
                {option.label}
              </label>
            </div>
          ))}
          {error && <span className="block text-red-500">{t ? t(error) : error}</span>}
        </div>
      </fieldset>
    </div>
  );
}
